import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpBaseService } from './http-base.service';

// 监装 api 接口
@Injectable({
    providedIn: 'root',
})
export class LoadingSupervisionService {
    pdfDown = new Subject<any>();
    pdfDown$ = this.pdfDown.asObservable();
    pdfImgLoadMap = {};
    pdfIsCanDown = false;
    pdfImgLoadSize = 0;
    pdfImgSize = 1;

    constructor(private httpBaseService: HttpBaseService) {}

    /**
     * 获取 监装 任务列表
     * @param page 页码
     * @param paginate 每页条数
     * @param loading_supervision_batch_no 批次号
     * @param search_value 工厂名
     * @param start_time 预计装柜开始日期
     * @param end_time 预计装柜结束日期
     * @param page_num 搜索查询专用
     * @param type 是否监装 is_loading_supervision ：已监装 不传type ：未监装
     * @param loading_supervision_name 监装人 姓名
     * @returns
     */
    getToBeAssignedList(
        page: number,
        paginate: number,
        loading_supervision_batch_no?: string,
        search_value?: string,
        start_time?: Date,
        end_time?: Date,
    ) {
        const params = {
            page,
            paginate,
            loading_supervision_batch_no,
            manufacturer: search_value,
            estimate_loading_start_date: this.formatDate(start_time),
            estimate_loading_end_date: this.formatDate(end_time),
        };
        this.clearUndefinedField(params);
        return this.httpBaseService.get('/loading_supervision/get_wait_loading_supervision', params);
    }

    getToAssignedList(
        page: number,
        paginate: number,
        loading_supervision_batch_no?: string,
        search_value?: string,
        loading_supervision_name?: string,
        start_time?: Date,
        end_time?: Date,
        type?: string,
        container_no?: string,
        contract_no?: string,
        sku?: string,
    ) {
        const params = {
            page,
            paginate,
            loading_supervision_batch_no,
            loading_supervision_name,
            manufacturer: search_value,
            estimate_loading_start_date: this.formatDate(start_time),
            estimate_loading_end_date: this.formatDate(end_time),
            type,
            container_no,
            contract_no,
            sku,
        };
        this.clearUndefinedField(params);
        return this.httpBaseService.get('/loading_supervision/get_loading_supervision_data', params);
    }

    getSkuDetailsLists(params: { loading_supervision_batch_no: string; contract_no: string; sku: string }) {
        // this.clearUndefinedField(params);
        return this.httpBaseService.get('/loading_supervision/get_cargos_details_by_pc', params);
    }

    /**
     * 获取 选择监装人列表
     */
    getGroupUseranddateList() {
        return this.httpBaseService.get('/inspection/select_group_useranddate_list');
    }

    /**
     * 分配监装人
     * @param id
     * @param loading_supervision_batch_no 监装批次号
     * @param bl_no 提单号
     * @param users 监装人数组
     * @param manufacturerArr 工厂名称数组
     */
    // distributionLoadingSupervisor(id: number, loading_supervision_batch_no: string, bl_no: string, users: any, manufacturerArr: string[]) {
    //   const params = { loading_supervision_data: { id, loading_supervision_batch_no, bl_no, users, manufacturerArr } }
    //   return this.httpBaseService.post('/loading_supervision/post_loading_supervision_data', params)
    // }
    distributionLoadingSupervisor(params: any) {
        return this.httpBaseService.post('/loading_supervision/post_loading_supervision_data', params);
    }

    /**
     * 撤销已分配监装人
     * @param loading_supervision_batch_no 批次号
     * @param id 排柜编号
     */
    cancelAssignedSupervisor(loading_supervision_batch_no: string) {
        const params = { loading_supervision_batch_no };
        return this.httpBaseService.post('/loading_supervision/del_loading_supervision_data', params);
    }

    /**
     * 获取监装报告数据
     */
    getLoadingSupervisionPdfData(loading_supervision_batch_no: string) {
        const params = { loading_supervision_batch_no };
        return this.httpBaseService.get('/loading_supervision/get_loading_supervision_pdf_data', params);
    }

    /**
     * 任务分配列表撤销
     */
    taskAssignmentCancellation(params) {
        return this.httpBaseService.post('/loading_supervision/cancel_loading_supervision_task', params);
    }

    /**
     * 清理 json 中的 undefined、null 字段
     */
    clearUndefinedField(obj: {}) {
        for (let key in obj) {
            const value = obj[key];
            if (value === undefined || value === null) {
                delete obj[key];
            }
        }
    }

    /**
     * 格式化日期 yyyy-MM-dd
     * @param date
     * @returns
     */
    formatDate(date: Date) {
        if (!date) {
            return;
        }
        let y: any = date.getFullYear();
        let m: any = date.getMonth() + 1;
        m = m < 10 ? '0' + m : m;
        let d: any = date.getDate();
        d = d < 10 ? '0' + d : d;
        return y + '-' + m + '-' + d;
    }
}
