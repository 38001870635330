import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArrayingContainerData } from 'src/app/pages/loading-cabinet/loading-cabinet.component';
import { GlobalRedoService } from 'src/app/services/global-redo.service';
import { NewArrayingContainerService } from 'src/app/services/new-arraying-container.service';
import { PageEffectService } from 'src/app/services/page-effect.service';

@Component({
    selector: 'app-batch-box',
    templateUrl: './batch-box.component.html',
    styleUrls: ['./batch-box.component.scss'],
})
export class BatchBoxComponent implements OnInit {
    @Input() arrayingContainerData: ArrayingContainerData;
    @Input() cursor: string;
    @Input() isShowClose = true;
    @Output() flush = new EventEmitter<string>();

    constructor(
        private newArrayingContainerService: NewArrayingContainerService,
        private pageEffectService: PageEffectService,
        private globalRedoService: GlobalRedoService,
    ) {}

    ngOnInit() {}

    close() {
        this.newArrayingContainerService
            .resetNewLoadingArrayingContainerData(this.arrayingContainerData.name)
            .subscribe(
                (result: any) => {
                    if (result.status == 1) {
                        this.pageEffectService.showToast({
                            message: result.message,
                            color: 'success',
                        });
                        this.globalRedoService.redo({ uid: 999999999 });
                    } else {
                        this.pageEffectService.showToast({
                            message: result.message,
                            color: 'danger',
                        });
                    }
                },
                (error: Error) => {
                    this.pageEffectService.showToast({
                        message: error.message,
                        color: 'danger',
                    });
                },
            );
    }
}
