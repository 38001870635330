import { PageEffectService } from '../../services/page-effect.service';
import { BaseDataService } from '../../services/base-data.service';
import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { UnloadingSupervisionService } from 'src/app/services/unloading-supervision.service';

interface TreeNodeInterface {
    key?: number;
    expand?: boolean;
    accessory_data?: IaccessoryData[];
    parent?: TreeNodeInterface;
    contract_no?: string;
    factory_code?: string;
    manufacturer?: string;
    manufacturer_address?: string;
    purchaser_user_name?: string;
    schedule_user_name?: string;
    sku?: string;
    sku_chinese_name?: string;
    truly_inspection_num?: string;
    arraying_container_num?: number;
}

interface IaccessoryData {
    key?: number;
    accessory_name?: string;
    accessory_no?: string;
    accessory_num?: number;
    picture?: string;
    sku?: string;
    estimate_loading_num?: number;
}

@Component({
    selector: 'app-add-unloading-task-popup',
    templateUrl: './add-unloading-task-popup.component.html',
    styleUrls: ['./add-unloading-task-popup.component.scss'],
})
export class AddUnloadingTaskPopupComponent implements OnInit {
    // 是否有编辑按钮
    @Input() isAllowEdit = false;

    _existsDataList: any;
    @Input() set existsDataList(input: any) {
        if (!!input) {
            this._existsDataList = input;
        }
    }

    @Input() set applyInspectId(input: number) {
        if (!!input) this._applyInspectId = input;
    }

    @Input() showBtnGroup: any;

    _applyInspectId: number;

    config: SwiperConfigInterface = {};
    @Input()
    showType: 'input' | 'list';

    @Input() showCancel?: boolean = true;

    public data: any = {
        data: {},
    };

    queryContractNo: string = '';
    contractNoLists: TreeNodeInterface[] = [];
    constructor(
        private unloadingSupervisionService: UnloadingSupervisionService,
        public baseData: BaseDataService,
        private effectCtrl: PageEffectService,
    ) {}

    ngOnInit() {}

    listOfParentData: TreeNodeInterface[] = [];
    queryContract() {
        this.unloadingSupervisionService.queryContract(this.queryContractNo).subscribe(res => {
            if (!res.length) return this.effectCtrl.showToast({ message: '未查询到数据', color: 'danger' });
            // res= res.reduce((pre, cur) => {
            //   return pre.concat(cur)
            // }, [])

            this._existsDataList.forEach(v => {
                res.forEach(p => {
                    if (v.contract_no === p.contract_no && v.sku === p.sku) {
                        p.truly_inspection_num -= v.arraying_container_num;
                    }
                });
            });
            this.contractNoLists = res;
            this.listOfParentData = this.fnOne(this.contractNoLists);
        });
    }

    fnOne(dataLists: TreeNodeInterface[]) {
        return dataLists.map((e, i) => {
            return {
                key: i,
                contract_no: e.contract_no,
                factory_code: e.factory_code,
                manufacturer: e.manufacturer,
                manufacturer_address: e.manufacturer_address,
                purchaser_user_name: e.purchaser_user_name,
                schedule_user_name: e.schedule_user_name,
                sku: e.sku,
                sku_chinese_name: e.sku_chinese_name,
                truly_inspection_num: e.truly_inspection_num,
                expand: false,
                accessory_data: this.fnTwo(e.accessory_data),
            };
        });
    }

    fnTwo(dataLists: IaccessoryData[]) {
        return dataLists.map((e, i) => {
            return {
                key: i,
                accessory_name: e.accessory_name,
                accessory_no: e.accessory_no,
                accessory_num: e.accessory_num,
                picture: e.picture,
                sku: e.sku,
            };
        });
    }

    enter(type: 'cancel' | 'enter') {
        if (type == 'enter') {
            let submitDataLists = this.listOfParentData.filter(item => {
                if (item.arraying_container_num > 0) {
                    item.accessory_data = item.accessory_data.filter(i => i.estimate_loading_num > 0);
                }
                return item.arraying_container_num > 0;
            });

            if (!submitDataLists.length) {
                this.effectCtrl.showToast({
                    message: '请输入卸货数量(卸货数量必须大于0)',
                    color: 'danger',
                });
                return;
            }

            this.effectCtrl.modalCtrl.dismiss({
                refresh: submitDataLists,
            });
            this.effectCtrl.showToast({
                message: '添加成功',
                color: 'success',
            });
        } else {
            this.effectCtrl.modalCtrl.dismiss({
                refresh: true,
            });
        }
    }

    numberChange(e, item) {
        if (Number.parseInt(e.target.value) < 0) {
            e.target.value = 0;
        }

        //必须为整数
        if (!Number.isInteger(Number(e.target.value))) {
            e.target.value = Math.trunc(Number(e.target.value));
        }
    }
}
