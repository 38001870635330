import { Component, Input, OnInit } from '@angular/core';

interface ReworkPlanHistory {
    new_plan_desc: string;
    new_rework_plan_video: string;
    new_rework_type: string;
    old_plan_desc: string;
    old_rework_plan_video: string;
    old_rework_type: string;
    problem_no: number;
    update_user: string;
    update_time: string;
    create_user: string;
    create_time: string;
    complete_time: string;
    delete_time: string;
    delete_user: string;
    complete_user: string;
    plan_desc: string;
    status: number;
    new_rework_plan_img: string[];
    old_rework_plan_img: string[];
    rework_plan_img: String[];
    rework_plan_video: String;
    rework_type: String;
}

@Component({
    selector: 'app-rework-history-record',
    templateUrl: './rework-history-record.component.html',
    styleUrls: ['./rework-history-record.component.scss'],
})
export class ReworkHistoryRecordComponent implements OnInit {
    @Input() reworkPlanHistoryList: ReworkPlanHistory[];

    constructor() {}

    ngOnInit() {}
}
