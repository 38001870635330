import { Component, Input, OnInit } from '@angular/core';
import { ArrayingContainerSku } from 'src/app/entity/ArrayingContainerSku';

@Component({
    selector: 'app-modal-sku',
    templateUrl: './modal-sku.component.html',
    styleUrls: ['./modal-sku.component.scss'],
})
export class ModalSkuComponent implements OnInit {
    // @Input() currentSkuInfo: ArrayingContainerSku;
    @Input() currentSkuInfo: any;

    constructor() {}

    ngOnInit() {}
}
