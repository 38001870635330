import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-coloured-cards',
    templateUrl: './coloured-cards.component.html',
    styleUrls: ['./coloured-cards.component.scss'],
})
export class ColouredCardsComponent implements OnInit {
    @Input() grdStart: string;
    @Input() grdEnd: string;
    @Input() width: number;
    @Input() height: number;
    @Input() vShadow: number;

    constructor() {}

    ngOnInit() {}
}
