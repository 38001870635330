import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-reply-mode',
    templateUrl: './reply-mode.component.html',
    styleUrls: ['./reply-mode.component.scss'],
})
export class ReplyModeComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
