import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MyUtils } from '../utils/my-utils';
import { BaseDataService, userInfo } from './base-data.service';

export interface Result<T> {
    current_page: number;
    data: T;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: string;
    to: number;
    total: number;
}

@Injectable({
    providedIn: 'root',
})
export class NewArrayingContainerService {
    apiUrl: string = environment.apiUrl;

    constructor(private baseData: BaseDataService, private myUtils: MyUtils, private http: HttpClient) {}

    getNeedArrayContainerList(
        page: number,
        paginate: number,
        manufacturer: string,
        sku: string,
        contract_no: string,
        apply_inspection_no: string,
        factory_simple_address: string,
    ) {
        const params = { page, paginate, manufacturer, sku, contract_no, apply_inspection_no, factory_simple_address };
        this.clearUndefinedField(params);
        return this.baseData.get({ url: '/new_arraying_container/get_need_array_container_list', params });
    }

    getNewDistributionContainerData(
        page: number,
        paginate: number,
        manufacturer: string,
        sku: string,
        contract_no: string,
        batch_no: string,
        factory_simple_address: string,
        type?: string,
    ) {
        const params = { page, paginate, manufacturer, sku, contract_no, batch_no, type, factory_simple_address };
        this.clearUndefinedField(params);
        return this.baseData.get({ url: '/new_arraying_container/get_new_distribution_container_data', params });
    }

    getSkuNumForDeliverGoodsPort(arrival_port_id: number) {
        return this.baseData.get({
            url: '/new_arraying_container/get_sku_num_for_deliver_goods_port',
            params: { arrival_port_id },
        });
    }

    postNewArrayingContainerData(
        deliver_goods_port: string,
        estimate_loading_time: string,
        desc: string,
        batchs_data: any[],
        deliver_goods_port_id: number,
        total_volume: number,
        total_outer_gross_weight: number,
        arrival_port_id: number,
        arrival_port: string,
    ) {
        const params = {
            deliver_goods_port,
            estimate_loading_time: this.myUtils.dateFormat('YYYY-mm-dd', estimate_loading_time),
            desc,
            batchs_data,
            deliver_goods_port_id,
            total_volume,
            total_outer_gross_weight,
            arrival_port_id,
            arrival_port,
        };
        return this.baseData.post({ url: '/new_arraying_container/post_new_arraying_container_data', params });
    }

    getDeliverGoodsPort() {
        return this.baseData.get({ url: '/new_arraying_container/get_deliver_goods_port' });
    }

    getArrivalPort() {
        return this.baseData.get({ url: '/new_arraying_container/get_arrival_port' });
    }

    getNeedArrayContainerDetails(id: number) {
        return this.baseData.get({ url: '/new_arraying_container/get_need_array_container_details', params: { id } });
    }

    getWaitLoadingCabinetData(
        paginate: number,
        page: number,
        deliver_goods_port_id: number,
        arrival_port_id: number,
        estimate_loading_time_start: Date,
        estimate_loading_time_end: Date,
        system_container_no?: string,
        is_booking_space?: number,
    ) {
        const params = {
            paginate,
            page,
            deliver_goods_port_id,
            arrival_port_id,
        };
        if (estimate_loading_time_start) {
            params['estimate_loading_time_start'] = this.myUtils.dateFormat('YYYY-mm-dd', estimate_loading_time_start);
        }
        if (estimate_loading_time_end) {
            params['estimate_loading_time_end'] = this.myUtils.dateFormat('YYYY-mm-dd', estimate_loading_time_end);
        }
        if (system_container_no) {
            params['system_container_no'] = system_container_no;
        }
        if (is_booking_space !== undefined) {
            params['is_booking_space'] = is_booking_space;
        }
        return this.baseData.get({ url: '/new_arraying_container/get_wait_loading_cabinet_data', params });
    }

    postArraycontainerData(ob: {}[]) {
        const params = {
            arraying_container_sku_arr: ob,
        };
        return this.baseData.post({ url: '/new_arraying_container/post_arraycontainer_data', params });
    }

    /**
     * 撤销排柜数据
     */
    postResetArraycontainerData(name: string) {
        return this.baseData.post({ url: '/new_arraying_container/post_reset_arraycontainer_data', params: { name } });
    }

    /**
     * 撤销已提单数据
     * @param name
     */
    resetNewLoadingArrayingContainerData(name: string) {
        return this.baseData.post({
            url: '/new_arraying_container/reset_new_loading_arraying_container_data',
            params: { name },
        });
    }

    /**
     * 提交真实装柜信息
     * @param system_container_id
     * @param real_container_no
     * @param seal_no
     * @param estimate_loading_time
     * @param on_board_date
     * @param estimated_arrival_time
     * @param charges
     * @returns
     */
    postNewRealArrayingContainerData(
        system_container_id: number,
        system_container_no: string,
        real_container_no: string,
        seal_no: string,
        estimate_loading_time: string,
        on_board_date: string,
        estimated_arrival_time: string,
        charges: any[],
    ) {
        const params = {
            system_container_id,
            system_container_no,
            real_container_no,
            seal_no,
            estimate_loading_time: this.myUtils.dateFormat('YYYY-mm-dd', estimate_loading_time),
            on_board_date,
            estimated_arrival_time: this.myUtils.dateFormat('YYYY-mm-dd', estimated_arrival_time),
            charges,
        };
        return this.baseData.post({ url: '/new_arraying_container/post_new_real_arraying_container_data', params });
    }

    updateLoadingArrayingContainerData(move_system_container_data_arr: any[]) {
        return this.baseData.post({
            url: '/new_arraying_container/update_loading_arraying_container_data',
            params: { move_system_container_data_arr },
        });
    }

    /**
     * 获取已装柜数据列表
     * @param paginate
     * @param page
     * @param deliver_goods_port_id
     * @param arrival_port_id
     * @param estimate_loading_time_start
     * @param estimate_loading_time_end
     * @returns
     */
    getFinalLoadingContainerData(
        paginate: number,
        page: number,
        deliver_goods_port_id: number,
        arrival_port_id: number,
        truely_loading_time_start: Date,
        truely_loading_time_end: Date,
        is_shipment: 0 | 1 | 2,
    ) {
        const params = {
            paginate,
            page,
            deliver_goods_port_id,
            arrival_port_id,
            is_shipment,
        };

        if (truely_loading_time_start) {
            params['truely_loading_time_start'] = this.myUtils.dateFormat('YYYY-mm-dd', truely_loading_time_start);
        }

        if (truely_loading_time_end) {
            params['truely_loading_time_end'] = this.myUtils.dateFormat('YYYY-mm-dd', truely_loading_time_end);
        }

        return this.baseData.get({ url: '/new_arraying_container/get_final_loading_container_data', params });
    }

    /**
     * 撤销已装柜列表数据
     * @param real_container_no
     */
    resetFinalLoadingContainerData(real_container_no: string) {
        return this.baseData.post({
            url: '/new_arraying_container/reset_final_loading_container_data',
            params: { real_container_no },
        });
    }

    /**
     * 列表Excel导出
     * @param type
     * @param arr
     * @returns
     */
    downExcelForArrayingContainer(
        type: 'need' | 'distribution',
        manufacturer: string,
        sku: string,
        contract_no: string,
        arr: string,
        status: string,
        batch_no?: string,
    ) {
        const params = { type, api_token: this.baseData.userInfo.api_token, arr, status };
        if (manufacturer) {
            params['manufacturer'] = manufacturer;
        }
        if (sku) {
            params['sku'] = sku;
        }
        if (contract_no) {
            params['contract_no'] = contract_no;
        }
        if (batch_no) {
            params['batch_no'] = batch_no;
        }

        return this.baseData.get({ url: '/new_arraying_container/down_excel_for_arraying_container', params });
    }

    /**
     * 待排柜sku详情
     * @param sku
     * @param contract_no
     * @returns
     */
    getArrayingContainerSkuDetails(sku: string, contract_no: string) {
        return this.baseData.get({
            url: '/new_arraying_container/get_arraying_container_sku_details',
            params: { sku, contract_no },
        });
    }

    /**
     * 获取合同详情数据
     * @param contract_id
     * @returns
     */
    getScheduleGetContractDetails(contract_id: number) {
        return this.baseData.get({ url: '/schedule/get_contract_details', params: { contract_id } });
    }

    // getInspectionInspectionsReworkGroup(type: string, page: number) {
    //   return this.baseData.get({ url: '/inspection/inspections_rework_group', params: { type, page } });
    // }

    getWaitInspectionReworkList(
        page: number,
        paginate: number,
        contract_no?: string,
        apply_inspection_no?: string,
        manufacturer?: string,
        manufacturer_address?: string,
    ) {
        const params = {
            page,
            paginate,
        };
        if (contract_no) {
            params['contract_no'] = contract_no;
        }
        if (apply_inspection_no) {
            params['apply_inspection_no'] = apply_inspection_no;
        }
        if (manufacturer) {
            params['manufacturer'] = manufacturer;
        }
        if (manufacturer_address) {
            params['manufacturer_address'] = manufacturer_address;
        }
        return this.baseData.get({ url: '/inspection/get_wait_inspection_rework_list', params });
    }

    /**
     * 待分配验货返工合同列表
     * @param page
     * @param paginate
     * @param contract_no
     * @param apply_inspection_no
     * @param manufacturer
     * @param manufacturer_address
     * @returns
     */
    getInspectionGetWaitInspectionReworkList(
        page: number,
        paginate: number,
        contract_no?: string,
        apply_inspection_no?: string,
        manufacturer?: string,
        manufacturer_address?: string,
    ) {
        const params = {
            page,
            paginate,
        };
        if (contract_no) {
            params['contract_no'] = contract_no;
        }
        if (apply_inspection_no) {
            params['apply_inspection_no'] = apply_inspection_no;
        }
        if (manufacturer) {
            params['manufacturer'] = manufacturer;
        }
        if (manufacturer_address) {
            params['manufacturer_address'] = manufacturer_address;
        }
        return this.baseData.get({ url: '/inspection/get_examine_inspection_rework_list', params });
    }

    delInspectionTaskReworkImage(rework_id: number, type: string, filename: string) {
        return this.baseData.post({
            url: '/task/del_inspection_task_rework_image',
            params: { rework_id, type, filename },
        });
    }

    taskAddInspectionReviewDescribe(rework_id: number, review_describe_arr: any[]) {
        const params = {
            rework_id,
            review_describe_arr,
        };
        return this.baseData.post({ url: '/task/add_inspection_review_describe', params });
    }

    /**
     * 已确认返工列表
     * @param page
     * @param paginate
     * @param apply_inspection_no
     * @param contract_no
     * @param manufacturer
     * @param factory_simple_address
     * @returns
     */
    inspectionInspectionsReworkGroup(
        page: number,
        paginate: number,
        apply_inspection_no?: string,
        contract_no?: string,
        manufacturer?: string,
        factory_simple_address?: string,
    ) {
        const params = { page, paginate };
        if (apply_inspection_no) {
            params['apply_inspection_no'] = apply_inspection_no;
        }
        if (contract_no) {
            params['contract_no'] = contract_no;
        }
        if (manufacturer) {
            params['manufacturer'] = manufacturer;
        }
        if (factory_simple_address) {
            params['factory_simple_address'] = factory_simple_address;
        }
        return this.baseData.get({ url: '/inspection/inspections_rework_group', params });
    }

    /**
     * 确认返工
     * @param apply_inspection_no
     * @param contract_no
     * @param sku
     * @returns
     */
    // inspectionNewInspectionTaskReWork(apply_inspection_no: string, contract_no: string, sku: string, inspection_date: string) {
    //   const params = { apply_inspection_no, contract_no, sku, inspection_date }
    //   return this.baseData.post({ url: '/inspection/new_inspection_task_re_work', params });
    // }

    inspectionNewInspectionTaskReWork(
        apply_inspection_no: string,
        inspection_date: string,
        contract_no: string,
        sku: string,
    ) {
        const params = { apply_inspection_no, inspection_date, contract_no, sku };
        return this.baseData.post({ url: '/inspection/new_rework_inspection', params });
    }

    /**
     * 提交质检部
     * @param apply_inspection_no
     * @param inspection_date
     * @returns
     */
    // submitQualityInspectionDepartment(apply_inspection_no: string, inspection_date: string) {
    //   const params = { apply_inspection_no, inspection_date }
    //   return this.baseData.post({ url: '/inspection/submit_rework_inspection_task', params });
    // }

    submitQualityInspectionDepartment(
        apply_inspection_no: string,
        contract_no: string,
        sku: string,
        inspection_date: string,
    ) {
        const params = { apply_inspection_no, contract_no, sku, inspection_date };
        return this.baseData.post({ url: '/inspection/new_inspection_task_re_work', params });
    }

    /**
     * 审核返工任务
     * @param apply_inspection_no
     * @param contract_no
     * @param sku
     * @returns
     */
    inspectionUpdateInspectionReworkStatus(apply_inspection_no: string, contract_no: string, sku: string) {
        const params = { apply_inspection_no, contract_no, sku };
        return this.baseData.post({ url: '/inspection/update_inspection_rework_status', params });
    }

    /**
     * 取消返工并提交排柜
     * @param apply_inspection_no
     * @param contract_no
     * @param sku
     * @returns
     */
    inspectionCancelReworkAndArrayContainer(
        apply_inspection_no: string,
        contract_no: string,
        sku: string,
        manufacturer: string,
    ) {
        const params = { apply_inspection_no, contract_no, sku, manufacturer };
        return this.baseData.post({ url: '/inspection/cancel_rework_and_array_container', params });
    }

    /**
     * 删除返工合同视频
     * @param rework_id
     * @param type
     * @param file
     * @returns
     */
    taskDelInspectionTaskReworkVideo(rework_id: string, type: string, filename: string) {
        const params = {
            rework_id,
            type,
            filename,
        };
        return this.baseData.post({ url: '/task/del_inspection_task_rework_video', params });
    }

    /**
     * 提交订舱信息
     * @param bookingSpace
     * @returns
     */
    postBookingSpace(bookingSpace: any) {
        const params = bookingSpace;

        return this.baseData.post({ url: '/new_arraying_container/post_booking_space', params });
    }

    /**
     * 查询指定订舱信息
     * @param system_container_no
     */
    getBookingSpace(system_container_no: string) {
        const params = { system_container_no };

        return this.baseData.get({ url: '/new_arraying_container/get_booking_space', params });
    }

    /**
     * 提交箱柜备注
     * @param id
     * @param remarks
     */
    postLadingBillRemarks(id: number, remarks: string, factory_charge: number) {
        const params = { id, remarks, factory_charge };

        return this.baseData.post({ url: '/new_arraying_container/post_lading_bill_charge_remarks', params });
    }

    /**
     * 查看该sku待排柜信息（加装额外货物）
     */
    getWaitArrayingContainerSku(
        manufacturer_arr: string[],
        has_sku_arr: string[],
        contract_no: string,
        sku: string,
        factory_simple_address: string,
        manufacturer: string,
    ) {
        let params = {
            contract_no,
            sku,
            factory_simple_address,
            manufacturer,
            manufacturer_arr,
            has_sku_arr,
        };

        params = JSON.parse(JSON.stringify(params));

        return this.baseData.post({ url: '/new_arraying_container/post_wait_arraying_container_sku', params });
    }

    /**
     * 查看该sku待排柜信息（超装）
     */
    postOverloadSku(
        batchs_data: any[],
        contract_no?: string,
        factory_simple_address?: string,
        manufacturer?: string,
        sku?: string,
    ) {
        let params = { batchs_data, contract_no, factory_simple_address, manufacturer, sku };

        params = JSON.parse(JSON.stringify(params));

        return this.baseData.post({ url: '/new_arraying_container/post_overload_sku', params });
    }

    /**
     * 获取加装sku数据
     * @param system_container_no
     */
    getRetrofittingSkuData(system_container_no: string) {
        return this.baseData.get({
            url: '/new_arraying_container/get_retrofitting_sku_data',
            params: { system_container_no },
        });
    }

    /**
     * 提交货柜加装信息
     */
    postRetrofittingSku(system_container_no: string, retrofitting_sku_arr: any[]) {
        const params = { system_container_no, retrofitting_sku_arr };

        return this.baseData.post({ url: '/new_arraying_container/post_retrofitting_sku', params });
    }

    /**
     * 提交货柜超装信息
     */
    postOverloadSkuData(
        real_container_no: string,
        seal_no: string,
        dead_weight: string,
        truely_loading_time: string,
        loading_place: string,
        estimated_arrival_time: string,
        system_container_no: string,
        overload_sku_arr: any[],
        truely_loading_sku_arr: any[],
        factory_charge: number,
        factory_remarks: string,
        retrofitting_sku_arr: any[],
    ) {
        const params = {
            real_container_no,
            seal_no,
            dead_weight,
            truely_loading_time: this.myUtils.dateFormat('YYYY-mm-dd', truely_loading_time),
            loading_place,
            estimated_arrival_time: this.myUtils.dateFormat('YYYY-mm-dd', estimated_arrival_time),
            system_container_no,
            overload_sku_arr,
            truely_loading_sku_arr,
            factory_charge,
            factory_remarks,
            retrofitting_sku_arr,
        };

        return this.baseData.post({ url: '/new_arraying_container/post_overload_sku_data', params });
    }

    /**
     * 撤销已加装sku数据
     * @param system_container_no
     * @param batch_no
     * @param apply_inspection_no
     * @param contract_no
     * @param sku
     */
    cancelRetrofittingSku(
        system_container_no: string,
        batch_no: string,
        apply_inspection_no: string,
        contract_no: string,
        sku: string,
    ) {
        const params = {
            system_container_no,
            batch_no,
            apply_inspection_no,
            contract_no,
            sku,
        };

        return this.baseData.post({ url: '/new_arraying_container/cancel_retrofitting_sku', params });
    }

    /**
     * 更新货柜明细
     * @param container_id
     * @param real_container_no
     * @param seal_no
     * @param dead_weight
     */
    updateContainerInfo(container_id: number, real_container_no: string, seal_no: string, dead_weight: string) {
        const params = {
            container_id,
            real_container_no,
            seal_no,
            dead_weight,
        };

        return this.baseData.post({ url: '/new_arraying_container/update_container_info', params });
    }

    /**
     * 更新货柜工厂费用
     */
    updateFactoryChargeInfo(
        container_id: number,
        loading_place: string,
        factory_charge: number,
        factory_remarks: string,
    ) {
        const params = {
            container_id,
            loading_place,
            factory_charge,
            factory_remarks,
        };

        return this.baseData.post({ url: '/new_arraying_container/update_factory_charge_info', params });
    }

    /**
     * 更新货柜货物批次及合同抬头信息
     * @param real_container_no
     * @param batch_no
     * @param final_loading_cabinet_num
     * @param contract_title
     * @returns
     */
    updateSkuAndContractTitle(
        real_container_no: string,
        batch_no: string,
        final_loading_cabinet_num: number,
        contract_title: string,
    ) {
        const params = {
            real_container_no,
            batch_no,
            final_loading_cabinet_num,
            contract_title,
        };

        return this.baseData.post({ url: '/new_arraying_container/update_sku_and_contract_title', params });
    }

    /**
     * 获取合同抬头
     * @returns
     */
    getContractTitle(batch_no: string, apply_inspection_no: string, contract_no: string, sku: string) {
        const params = {
            batch_no,
            apply_inspection_no,
            contract_no,
            sku,
        };

        return this.baseData.get({ url: '/new_arraying_container/get_contract_title', params });
    }

    /**
     *
     * @param real_container_no
     * @param type
     * @param file
     * @param container_id
     */
    uploadContainerImg(real_container_no: string, type: string, file: any, container_id: number) {
        let formData = new FormData();
        formData.append('real_container_no', real_container_no);
        formData.append('type', type);
        formData.append('container_id', container_id.toString());
        formData.append('file', file as any, file.name);

        return this.http.post(this.apiUrl + '/new_arraying_container/upload_container_img', formData, {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                Accept: 'multipart/form-data',
                Authorization: this.baseData.userInfo.api_token
                    ? `Bearer ${this.baseData.userInfo.api_token}`
                    : undefined,
            },
        });
    }

    /**
     *
     * @param real_container_no
     * @param filename
     * @returns
     */
    deleteContainerImg(real_container_no: string, filename: string) {
        const params = {
            real_container_no,
            filename,
            type: 'other_img',
        };

        return this.baseData.post({ url: '/new_arraying_container/delete_container_img', params });
    }

    /**
     * 更新货柜出运状态
     * @param real_container_no
     * @returns
     */
    postContainerShipmentStatus(real_container_no: string) {
        return this.baseData.post({
            url: '/new_arraying_container/post_container_shipment_status',
            params: { real_container_no },
        });
    }

    // getBase64 = (imageUrl: string) => {
    //   fetch(imageUrl, {mode: 'cors'})
    //     .then((response) => {
    //       response.blob()
    //         .then((blob) => {
    //           //('blob', blob);

    //           let oFileReader = new FileReader();
    //           oFileReader.onloadend = (e: any) => {
    //             let base64 = e.target.result;
    //             //('base64', base64);

    //           };
    //           oFileReader.readAsDataURL(blob);
    //         })
    //     })
    // };

    /**
     * 获取货柜图片base64
     * @param img_path
     */
    getContainerImgBase64(img_path: string) {
        return fetch(this.apiUrl + '/new_arraying_container/get_container_img_base_64?img_path=' + img_path, {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                Accept: 'multipart/form-data',
                Authorization: this.baseData.userInfo.api_token
                    ? `Bearer ${this.baseData.userInfo.api_token}`
                    : undefined,
            },
        });
    }

    /**
     * 清理 json 中的 undefined、null 字段
     */
    private clearUndefinedField(obj: {}) {
        for (let key in obj) {
            const value = obj[key];
            if (value === undefined || value === null || value === '') {
                delete obj[key];
            }
        }
    }

    /**
     * 获取袋装柜详情
     */
    getCabinetInstalled(system_container_no: string) {
        const params = { system_container_no };

        return this.baseData.get({ url: '/new_arraying_container/get_wait_loading_cabinet_detail', params });
    }

    /**
     * 取消合同拆分
     */
    sureUndoSplit(params: {}) {
        return this.baseData.post({ url: '/new_arraying_container/cancel_contract_split', params });
    }

    /**
     * 取消合同拆分
     */
    sureNewSlipt(params: {}) {
        return this.baseData.post({ url: '/new_arraying_container/post_split_contract_data', params });
    }

    /**
     * 获取货柜列表
     */
    getContainerLists(params: any) {
        return this.baseData.get({ url: '/arraying_container/get_array_container_list', params });
    }

    /**
     * 获取货柜详情列表
     */
    getContainerDetailsLists(params: any) {
        return this.baseData.get({ url: '/arraying_container/get_array_container_info', params });
    }

    /**
     * 创建货柜
     */
    postCreateContainer(params: any) {
        return this.baseData.post({ url: '/arraying_container/create_container_info', params });
    }

    /**
     * 修改货柜
     */
    postEditContainer(params: any) {
        return this.baseData.post({ url: '/arraying_container/update_array_container_cabinet', params });
    }

    /**
     * 获取列表sku详情
     */
    getListSkuDetails(params: any) {
        return this.baseData.get({ url: '/arraying_container/get_array_container_sku_info', params });
    }

    /**
     * 创建货柜
     */
    postSubmitCabinetInfo(params: any) {
        return this.baseData.post({ url: '/arraying_container/post_container_cabinet_info', params });
    }

    /**
     * 获取可排柜sku
     */
    getRowCabinetSKU(params: any) {
        return this.baseData.get({ url: '/arraying_container/get_can_array_container_sku', params });
    }

    /**
     * 添加货物
     */
    postAddGoods(params: any) {
        return this.baseData.post({ url: '/arraying_container/add_array_container_sku', params });
    }

    /**
     * 修改货物信息
     */
    postEditGoods(params: any) {
        return this.baseData.post({ url: '/arraying_container/update_array_container_sku', params });
    }

    /**
     * 删除货物信息
     */
    postDeleteGoods(params: any) {
        return this.baseData.post({ url: '/arraying_container/delete_array_container_sku', params });
    }

    /**
     * 加装货物
     */
    postAddReplenishment(params: any) {
        return this.baseData.post({ url: '/arraying_container/add_replenishment_sku', params });
    }

    /**
     * 上传照片
     */
    uploadShipmentImg(batch_no: string, type: string, file: any) {
        let formData = new FormData();
        formData.append('batch_no', batch_no);
        formData.append('type', type);
        formData.append('file', file as any, file.name);

        return this.http.post(this.apiUrl + '/arraying_container/upload_container_img', formData, {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                Accept: 'multipart/form-data',
                Authorization: this.baseData.userInfo.api_token
                    ? `Bearer ${this.baseData.userInfo.api_token}`
                    : undefined,
            },
        });
    }

    /**
     * 删除照片
     */
    postDeleteShipmentImg(params: any) {
        return this.baseData.post({ url: '/arraying_container/delete_container_img', params });
    }
}
