import { PageEffectService } from '../../services/page-effect.service';
import { BaseDataService } from '../../services/base-data.service';
import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NewArrayingContainerService } from 'src/app/services/new-arraying-container.service';
import { NzMessageService } from 'ng-zorro-antd';

interface TreeNodeInterface {
    key?: number;
    expand?: boolean;
    accessory_data?: IaccessoryData[];
    parent?: TreeNodeInterface;
    contract_no?: string;
    factory_code?: string;
    manufacturer?: string;
    manufacturer_address?: string;
    purchaser_user_name?: string;
    schedule_user_name?: string;
    sku?: string;
    sku_chinese_name?: string;
    truly_inspection_num?: string;
    arraying_container_num?: number;
}

interface IaccessoryData {
    key?: number;
    accessory_name?: string;
    accessory_no?: string;
    accessory_num?: number;
    picture?: string;
    sku?: string;
    estimate_loading_num?: number;
}

@Component({
    selector: 'app-create-supervision-popup',
    templateUrl: './add-cargo-popup.component.html',
    styleUrls: ['./add-cargo-popup.component.scss'],
})
export class AddCargoPopupComponent implements OnInit {
    // 是否有编辑按钮
    @Input() batch_no;

    // _existsDataList: any;
    // @Input() set existsDataList(input: any) {
    //   if (!!input) {
    //     this._existsDataList = input;
    //   }
    // }

    selectLists: string[] = ['新鼎瑞利', '新鼎(不开票)', '海曙蔻特', '国际合作', 'WLD', 'LOTOLE', 'JAXPETY'];
    queryContractNo: string = '';
    contractNoLists: TreeNodeInterface[] = [];
    constructor(
        private newArrayingContainerService: NewArrayingContainerService,
        public baseData: BaseDataService,
        private effectCtrl: PageEffectService,
        private msg: NzMessageService,
    ) {}

    ngOnInit() {}

    listOfParentData: any = [];
    queryContract() {
        let params = {
            param: this.queryContractNo,
        };
        this.newArrayingContainerService.getRowCabinetSKU(params).subscribe(res => {
            if (res.status === 1) {
                this.listOfParentData = res.data;
            }
            // res= res.reduce((pre, cur) => {
            //   return pre.concat(cur)
            // }, [])

            // this._existsDataList.forEach(v => {
            //   res.forEach(p => {
            //     if (v.contract_no === p.contract_no && v.sku === p.sku) {
            //       p.truly_inspection_num -= v.arraying_container_num
            //     }
            //   })
            // })
            // this.contractNoLists = res
            // this.listOfParentData = this.fnOne(this.contractNoLists)
        });
    }

    fnOne(dataLists: TreeNodeInterface[]) {
        return dataLists.map((e, i) => {
            return {
                key: i,
                contract_no: e.contract_no,
                factory_code: e.factory_code,
                manufacturer: e.manufacturer,
                manufacturer_address: e.manufacturer_address,
                purchaser_user_name: e.purchaser_user_name,
                schedule_user_name: e.schedule_user_name,
                sku: e.sku,
                sku_chinese_name: e.sku_chinese_name,
                truly_inspection_num: e.truly_inspection_num,
                expand: false,
                accessory_data: this.fnTwo(e.accessory_data),
            };
        });
    }

    fnTwo(dataLists: IaccessoryData[]) {
        return dataLists.map((e, i) => {
            return {
                key: i,
                accessory_name: e.accessory_name,
                accessory_no: e.accessory_no,
                accessory_num: e.accessory_num,
                picture: e.picture,
                sku: e.sku,
            };
        });
    }

    enter(type: 'cancel' | 'enter') {
        if (type == 'enter') {
            // let isSubmit = this.contractNoLists.every(item => {
            //   return item.arraying_container_num
            // })

            // if (!isSubmit) {
            //   this.effectCtrl.showToast({
            //     message: '请输入装柜箱数',
            //     color: 'danger'
            //   })
            //   return
            // }

            // let submitDataLists = this.listOfParentData.filter(item => {
            //   if (item.arraying_container_num >= 0) {
            //     item.accessory_data = item.accessory_data.filter(i => i.estimate_loading_num > 0)
            //   }
            //   return item.arraying_container_num >= 0
            // })

            // if (!submitDataLists.length) {
            //   this.effectCtrl.showToast({
            //     message: '请输入装柜箱数',
            //     color: 'danger'
            //   })
            //   return
            // }

            let filterData = this.listOfParentData.filter(e => e.loading_num);
            filterData.forEach(item => {
                item.contract_title = item.contract_title || '';
                item.payment_method = item.payment_method || '';
            });

            let arr = [];
            this.listOfParentData.forEach(e => {
                e.accessory_data.filter(item => {
                    item.loading_num &&
                        arr.push({
                            bar_code: item.bar_code,
                            contract_no: e.contract_no,
                            contract_title: item.contract_title || '',
                            loading_num: item.loading_num,
                            manufacturer: e.manufacturer,
                            sku: item.accessory_no,
                            payment_method: item.payment_method || '',
                            plan_delivery_time: e.plan_delivery_time,
                            schedule_user_name: e.schedule_user_name,
                            sku_chinese_name: item.accessory_name,
                            accessory_type: 1,
                        });
                });
            });

            let params = {
                batch_no: this.batch_no,
                array_container_data: this.listOfParentData
                    .filter(
                        e =>
                            e.loading_num &&
                            (e.accessory_data && e.accessory_data.length <= 0 && e.sku && e.sku.indexOf('PJ') === 0
                                ? (e.accessory_type = 2)
                                : true),
                    )
                    .concat(arr),
            };

            this.newArrayingContainerService.postAddGoods(params).subscribe(res => {
                if (res.status === 1) {
                    this.effectCtrl.modalCtrl.dismiss({
                        refresh: '添加成功',
                    });
                    this.effectCtrl.showToast({
                        message: '添加成功',
                        color: 'success',
                    });
                } else {
                    this.effectCtrl.showToast({
                        message: res.message,
                        color: 'danger',
                    });
                }
            });
        } else {
            this.effectCtrl.modalCtrl.dismiss({
                refresh: true,
            });
        }
    }

    numberChange(e, item) {
        // if (e.target.value > item.truly_inspection_num) {
        //   e.target.value = item.truly_inspection_num
        // } else if (Number.parseInt(e.target.value) < 1) {
        //   e.target.value = 1
        // }
        if (Number.parseInt(e.target.value) < 0) {
            e.target.value = 0;
        }
    }
}
