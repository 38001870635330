import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { differenceInCalendarDays } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd';
import { DataStatisticsService } from 'src/app/services/data-statistics.service';
import { MyUtils } from 'src/app/utils/my-utils';

export enum TaskStatus {
    '待确认',
    '未解决',
    '待关闭',
    '过期',
    '延期',
    '已关闭',
    '已取消',
}

export enum TaskRelevant {
    create = 'create',
    assigned = 'assigned',
    completed = 'completed',
    confirmed = 'confirmed',
}

enum SortKey {
    task_no = 'task_no',
    priority = 'priority',
    created_at = 'created_at',
    end_time = 'end_time',
}

enum SortValue {
    asc = 'asc',
    desc = 'desc',
}

export interface Task {
    acceptance_criteria?: string;
    assigned_person_id?: number;
    cc_people_id?: any[];
    cc_people_name?: string[];
    close_reason?: string;
    close_time?: string;
    complete_time?: string;
    completed_by?: string;
    completed_by_id?: number;
    confirmed_by?: string;
    confirmed_by_id?: number;
    create_user_id?: number;
    created_at?: string;
    end_time?: string;
    id?: number;
    is_confirm?: string;
    priority?: number;
    remarks?: string;
    status?: number;
    task_describe?: string;
    task_no?: string;
    task_title?: string;
    type?: number;
    updated_at?: string;
    assigned_person?: string;
    create_user?: string;
    update_content_arr?: any[];
    is_cc?: 0 | 1;
    is_assigned?: 0 | 1;
    is_confirmed_by?: 0 | 1;
    is_closed_person?: 0 | 1;
    is_completed?: 0 | 1;
}

@Component({
    selector: 'app-task-type-table',
    templateUrl: './task-type-table.component.html',
    styleUrls: ['./task-type-table.component.scss'],
})
export class TaskTypeTable implements OnInit {
    taskStatusList = ['待确认', '未解决', '待关闭', '过期', '延期', '已关闭', '已取消'];

    sortName: string | null = null;
    sortValue: string | null = null;
    searchAddress: string;
    listOfSearchName: string[] = [];

    date: any;
    priority1: any;
    selectedValue: any;
    closingReason: string;
    onChange() {}

    SortKey = SortKey;

    paginate = 10;
    page = 1;
    total: number;
    sort_key = SortKey.created_at;
    sort_value = SortValue.desc;

    @Output() taskTypeList = new EventEmitter();
    taskType: string[];

    private _status = undefined;
    @Input()
    set status(status: TaskStatus) {
        this._status = status;
        if (this._status !== undefined && this._status !== null) {
            this._relevant = undefined;
            this.page = 1;
            this.getTaskManageList();
        }
    }
    get status() {
        return this._status;
    }

    private _relevant = undefined;
    @Input()
    set relevant(relevant: TaskRelevant) {
        this._relevant = relevant;
        if (this._relevant !== undefined && this._relevant !== null) {
            this._status = undefined;
            this.page = 1;
            this.getTaskManageList();
        }
    }
    get relevant() {
        return this._relevant;
    }

    private _searchAll = false;
    @Input()
    set searchAll(searchAll: boolean) {
        this._searchAll = searchAll;
        if (this._searchAll) {
            this.status = undefined;
            this.relevant = undefined;
            this.type = undefined;
            this.page = 1;
            this.getTaskManageList();
        }
    }

    private _type = undefined;
    @Input()
    set type(taskType: number) {
        this._type = taskType;
        if (this._type !== undefined && this._type !== null) {
            this.page = 1;
            this.getTaskManageList();
        }
    }
    get type() {
        return this._type;
    }

    list: Task[] = [];

    constructor(
        private dataStatisticsService: DataStatisticsService,
        private router: Router,
        private msg: NzMessageService,
        private myUtils: MyUtils,
    ) {}

    ngOnInit() {
        this.init();
    }

    init() {
        this.getTaskManageList();
    }

    getTaskManageList() {
        if (this.status === TaskStatus.待确认) {
            this._relevant = TaskRelevant.confirmed;
        }
        this.dataStatisticsService
            .getTaskManageList(
                this.paginate,
                this.page,
                this.sort_key,
                this.sort_value,
                this.type,
                this.status,
                this.relevant,
            )
            .subscribe(result => {
                this.list = result.data.data;
                this.page = +result.data.current_page;
                this.paginate = +result.data.per_page;
                this.total = +result.data.total;

                this.taskTypeList.emit(result.task_type);
                this.taskType = result.task_type;
            });
    }

    nzPageIndexChange(val: number) {
        this.page = val;
        this.init();
    }

    see(id: number) {
        this.router.navigate(['/dashboard/urgent-task-details'], { queryParams: { id } });
    }

    alterTask(item: Task) {
        this.router.navigate(['/dashboard/urgent-task-details'], { queryParams: { id: item.id, isAllowEdit: 1 } });
    }

    sort(sort: { key: SortKey; value: string }): void {
        if (!sort.value) {
            return;
        }

        this.sort_key = sort.key;

        if (sort.value === 'descend') {
            this.sort_value = SortValue.desc;
        } else {
            this.sort_value = SortValue.asc;
        }

        this.getTaskManageList();
    }

    filter(listOfSearchName: string[], searchAddress: string): void {
        this.listOfSearchName = listOfSearchName;
        this.searchAddress = searchAddress;
    }

    // 确认
    isSure = false;
    currentTask: Task;
    showSure(task: Task): void {
        this.currentTask = { ...task };
        this.loadHistoricalRecords(this.currentTask.update_content_arr);
        this.isSure = true;
    }
    sureCancel(): void {
        this.isSure = false;
    }

    // 完成
    isComplete = false;
    showComplete(task: Task): void {
        this.completeRemarks = undefined;
        this.currentTask = { ...task };
        this.loadHistoricalRecords(this.currentTask.update_content_arr);
        this.isComplete = true;
    }
    completeCancel(): void {
        this.isComplete = false;
    }
    completeTimeChange(date: Date) {
        this.currentTask.complete_time = this.myUtils.dateFormat('YYYY-mm-dd', date);
    }
    completeRemarks: string;
    completeSave(task: Task) {
        if (!this.myUtils.existValidation([this.currentTask.complete_time])) {
            this.msg.warning('必填项不能为空！');
            return;
        }

        this.dataStatisticsService
            .postCompleteTask(task.id, task.complete_time, this.completeRemarks)
            .subscribe(result => {
                this.msg[result.status ? 'success' : 'error'](result.message);
                this.isComplete = false;
                this.init();
            });
    }

    //  关闭
    isClose = false;
    showClose(task: Task): void {
        this.closingReason = undefined;
        this.closeRemarks = undefined;
        this.currentTask = { ...task };
        this.loadHistoricalRecords(this.currentTask.update_content_arr);
        this.isClose = true;
    }
    closeCancel(): void {
        this.isClose = false;
    }
    closeRemarks: string;
    closeSave(task: Task) {
        if (!this.myUtils.existValidation([this.closingReason])) {
            this.msg.warning('必填项不能为空！');
            return;
        }
        this.dataStatisticsService
            .postCloseTask(task.id, this.closingReason, this.closingReason == '已解决' ? 5 : 6, this.closeRemarks)
            .subscribe(result => {
                this.msg[result.status ? 'success' : 'error'](result.message);
                this.isClose = false;
                this.init();
            });
    }

    //  查看
    isSee = false;
    showSEE(): void {
        this.isSee = true;
    }
    seeCancel(): void {
        this.isSee = false;
    }

    sureSave(task: Task) {
        if (!this.myUtils.existValidation([this.currentTask.assigned_person])) {
            this.msg.warning('必填项不能为空！');
            return;
        }
        this.dataStatisticsService
            .postConfirmTask(task.assigned_person, task.cc_people_id, task.cc_people_name, task.remarks, task.id)
            .subscribe(result => {
                this.msg[result.status ? 'success' : 'error'](result.message);
                this.isSure = false;
                this.init();
            });
    }

    createTask: any;
    closeTask: any;
    confirmTask: any;
    completedTask: any;
    tempUpdateTaskList: any[] = [];

    /**
     * 装载历史记录
     */
    loadHistoricalRecords(data: any[]) {
        if (!data) {
            return;
        }

        this.createTask = undefined;
        this.closeTask = undefined;
        this.confirmTask = undefined;
        this.completedTask = undefined;
        this.tempUpdateTaskList = [];

        const tempCreateTask = data.filter(item => item.operation_type === 'create_task');
        if (tempCreateTask && tempCreateTask.length > 0) {
            this.createTask = tempCreateTask[0];
        }

        const tempCloseTask = data.filter(item => item.operation_type === 'close_task');
        if (tempCloseTask && tempCloseTask.length > 0) {
            this.closeTask = tempCloseTask[0];
        }

        const tempConfirmTask = data.filter(item => item.operation_type === 'confirm_task');
        if (tempConfirmTask && tempConfirmTask.length > 0) {
            this.confirmTask = tempConfirmTask[0];
        }

        const tempCompletedTask = data.filter(item => item.operation_type === 'completed_task');
        if (tempCompletedTask && tempCompletedTask.length > 0) {
            this.completedTask = tempCompletedTask[0];
        }

        const tempUpdateTask = data.filter(item => item.operation_type === 'update_task');
        if (tempUpdateTask && tempUpdateTask.length > 0) {
            this.tempUpdateTaskList = tempUpdateTask;
        }
    }

    exportData() {
        if (this.list.length === 0) {
            alert('列表无数据可供导出！');
            return;
        }

        this.dataStatisticsService.downTaskManageDataExcel(
            this.paginate,
            this.page,
            this.sort_key,
            this.sort_value,
            this.type,
            this.status,
            this.relevant,
        );
    }

    disabledDate = (current: Date): boolean => {
        return differenceInCalendarDays(current, new Date(this.currentTask.created_at)) < 0;
    };
}
