import { FunnelComponent } from './funnel/funnel.component';
import { ReplyModeComponent } from './reply-mode/reply-mode.component';
import { CommitReplyComponent } from './commit-reply/commit-reply.component';
import { NgZorroAntdModule, NzTreeService } from 'ng-zorro-antd';
import { TrackExcludeComponent } from './track-exclude/track-exclude.component';
import { ConfirmedPopupBoxComponent } from './confirmed-popupbox/confirmed-popupbox.component';
import { TdOverflowTextComponent } from './td-overflow-text/td-overflow-text.component';
import { LoaddingComponent } from './loadding/loadding.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SortComponent } from './sort/sort.component';
import { DirectiveModule } from './../directive/directive.module';
import { ProgressComponent } from './progress/progress.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule } from '@angular/forms';
import { UploadimgComponent } from './uploadimg/uploadimg.component';
import { NoDataShowComponent } from './no-data-show/no-data-show.component';
import { ReplyComponent } from './reply/reply.component';
import { CompareTextPipe } from '../pipe/compare-text.pipe';
import { PermissionTreeComponent } from './permission-tree/permission-tree.component';
import { SkuInfoComponent } from './sku-info/sku-info.component';
import { EditorComponent } from './editor/editor.component';
import { ModalSkuComponent } from './modal-sku/modal-sku.component';
import { SkeletonImgComponent } from 'src/app/component/skeleton-img/skeleton-img.component';
import { PdfImgComponent } from './pdf-img/pdf-img.component';
import { RadioCardComponent } from './radio-card/radio-card.component';
import { ContainerBoxComponent } from './container-box/container-box.component';
import { SimpleBatchBoxComponent } from './simple-batch-box/simple-batch-box.component';
import { BatchBoxComponent } from './batch-box/batch-box.component';
import { FiveUploadChunkComponent } from './five-upload-chunk/five-upload-chunk.component';
import { EnergyDiskComponent } from './energy-disk/energy-disk.component';
import { ColouredCardsComponent } from './coloured-cards/coloured-cards.component';
import { IntoIconComponent } from './into-icon/into-icon.component';
import { CollaborativeTask } from './collaborative-task/collaborative-task.component';
import { TaskTypeTable } from './task-type-table/task-type-table.component';
import { SelectUserComponent } from './select-user/select-user.component';
import { SelectOneUserComponent } from './select-one-user/select-one-user.component';
import { QuillModule } from 'ngx-quill';
import { HistoryRecordComponent } from './history-record/history-record.component';
import { ProductDepSupplementaryNotesComponent } from './product-dep-supplementary-notes/product-dep-supplementary-notes.component';
import { SupplementaryNotesSelectComponent } from './supplementary-notes-select/supplementary-notes-select.component';
import { ReworkHistoryRecordComponent } from './rework-history-record/rework-history-record.component';

@NgModule({
    declarations: [
        ProgressComponent,
        SortComponent,
        PaginationComponent,
        UploadimgComponent,
        LoaddingComponent,
        NoDataShowComponent,
        TdOverflowTextComponent,
        ConfirmedPopupBoxComponent,
        TrackExcludeComponent,
        ReplyComponent,
        ReplyModeComponent,
        FunnelComponent,
        CompareTextPipe,
        PermissionTreeComponent,
        SkuInfoComponent,
        CommitReplyComponent,
        EditorComponent,
        ModalSkuComponent,
        SkeletonImgComponent,
        PdfImgComponent,
        RadioCardComponent,
        ContainerBoxComponent,
        SimpleBatchBoxComponent,
        BatchBoxComponent,
        FiveUploadChunkComponent,
        EnergyDiskComponent,
        ColouredCardsComponent,
        IntoIconComponent,
        CollaborativeTask,
        TaskTypeTable,
        SelectUserComponent,
        SelectOneUserComponent,
        HistoryRecordComponent,
        ProductDepSupplementaryNotesComponent,
        SupplementaryNotesSelectComponent,
        ReworkHistoryRecordComponent,
    ],
    imports: [
        CommonModule,
        DirectiveModule,
        IonicModule,
        FormsModule,
        NgZorroAntdModule,
        FileUploadModule,
        QuillModule.forRoot(),
    ],
    exports: [
        ProgressComponent,
        SortComponent,
        PaginationComponent,
        UploadimgComponent,
        LoaddingComponent,
        NoDataShowComponent,
        TdOverflowTextComponent,
        ConfirmedPopupBoxComponent,
        TrackExcludeComponent,
        ReplyComponent,
        ReplyModeComponent,
        FunnelComponent,
        CompareTextPipe,
        PermissionTreeComponent,
        SkuInfoComponent,
        CommitReplyComponent,
        EditorComponent,
        ModalSkuComponent,
        SkeletonImgComponent,
        PdfImgComponent,
        RadioCardComponent,
        ContainerBoxComponent,
        SimpleBatchBoxComponent,
        BatchBoxComponent,
        FiveUploadChunkComponent,
        EnergyDiskComponent,
        ColouredCardsComponent,
        IntoIconComponent,
        CollaborativeTask,
        TaskTypeTable,
        SelectUserComponent,
        SelectOneUserComponent,
        HistoryRecordComponent,
        ProductDepSupplementaryNotesComponent,
        SupplementaryNotesSelectComponent,
        ReworkHistoryRecordComponent,
    ],
    providers: [NzTreeService, FileReader],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentModule {}
