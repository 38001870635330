import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MyUtils } from '../utils/my-utils';
import { BaseDataService } from './base-data.service';

@Injectable({
    providedIn: 'root',
})
export class SkuTrackService {
    apiUrl: string = environment.apiUrl;

    constructor(private baseData: BaseDataService, private myUtils: MyUtils, private http: HttpClient) {}

    /**
     * 获取sku补充说明信息
     * @param contract_no
     * @param sku
     * @returns
     */
    getSkuNumForDeliverGoodsPort(contract_no: string, sku: string) {
        return this.baseData.get({ url: '/sku/get_sku_supplementary_note', params: { contract_no, sku } });
    }

    /**
     * 提交sku补充说明信息
     * @param contract_no
     * @param sku
     * @param supplementary_note
     * @param label
     */
    postSkuSupplementaryNote(contract_no: string, sku: string, supplementary_note: string, label: number, id: number) {
        let params = {
            contract_no,
            sku,
            supplementary_note,
            label,
            id,
        };

        params = JSON.parse(JSON.stringify(params));

        return this.baseData.post({ url: '/sku/post_sku_supplementary_note', params });
    }

    /**
     * 删除sku补充说明信息
     * @param contract_no
     * @param sku
     * @param index
     * @returns
     */
    delSkuSupplementaryNote(contract_no: string, sku: string, index: number) {
        let params = {
            contract_no,
            sku,
            index,
        };

        params = JSON.parse(JSON.stringify(params));

        return this.baseData.post({ url: '/sku/del_sku_supplementary_note', params });
    }

    /**
     * 获取sku跟踪统计数据
     * @param sku
     * @param paginate
     * @param page
     * @returns
     */
    getScheduleSkuAll(sku: string, paginate: number, page: number) {
        let params = {
            sku,
            paginate,
            page,
        };

        params = JSON.parse(JSON.stringify(params));

        return this.baseData.get({ url: '/sku/get_schedule_sku_all', params });
    }

    /**
     * 删除sku补充说明
     * @param contract_no
     * @param sku
     */
    delSkuSupplementaryLabel(contract_no: string, sku: string, label: number) {
        const params = { contract_no, sku, label };

        return this.baseData.post({ url: '/sku/del_sku_supplementary_label', params });
    }
}
