import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService, UploadFile, UploadXHRArgs } from 'ng-zorro-antd';
import { NewArrayingContainerService } from 'src/app/services/new-arraying-container.service';
import { environment } from 'src/environments/environment';
import { FiveUploadChunkService } from './five-upload-chunk.service';

@Component({
    selector: 'app-five-upload-chunk',
    templateUrl: './five-upload-chunk.component.html',
    styleUrls: ['./five-upload-chunk.component.scss'],
})
export class FiveUploadChunkComponent implements OnInit {
    env: any = environment;
    @Input()
    get reworkId() {
        return this._reworkId;
    }
    set reworkId(value: number) {
        if (this._reworkId != value) {
            this._reworkId = value;
            this.init();
        }
    }
    _reworkId: number;

    alreadyUploadVideoList = [];
    videoPlayUrl: string;

    isUploading = false;
    isShowStopButtom = true;

    percent: { percent: number };

    constructor(
        private fiveUploadChunkService: FiveUploadChunkService,
        private newArrayingContainerService: NewArrayingContainerService,
        private msg: NzMessageService,
    ) {}

    ngOnInit() {
        this.init();
    }

    init() {
        this.alreadyUploadVideoList = [];
        this.videoPlayUrl = null;
        this.isUploading = false;
        this.isShowStopButtom = true;
        this.percent = this.fiveUploadChunkService.percent;
    }

    customReq = (item: UploadXHRArgs) => {
        this.isUploading = true;
        this.fiveUploadChunkService.uploadByPieces(
            item.file as any,
            30,
            (successData: any) => {
                this.alreadyUploadVideoList.push(successData);
                //('上传成功');
                this.isUploading = false;
            },
            () => {
                //('上传失败');
                this.isUploading = false;
            },
            item,
            this.reworkId,
        );
    };

    remove = async (uid: string) => {
        //('uid---', uid);

        this.alreadyUploadVideoList = this.alreadyUploadVideoList.filter(item => item.uid != uid);

        const data = this.fiveUploadChunkService.getSuccessData(uid);
        if (data) {
            const result = await this.newArrayingContainerService
                .taskDelInspectionTaskReworkVideo(data.rework_id, data.type, data.path)
                .toPromise();
            if (result.status) {
                this.msg.success('删除成功！');
                return true;
            } else {
                this.msg.error('删除失败！');
                return false;
            }
        } else {
            return false;
        }
    };

    continue() {
        this.isShowStopButtom = true;
        this.fiveUploadChunkService.continueUpload();
    }

    stop() {
        this.isShowStopButtom = false;
        this.fiveUploadChunkService.stopUpload();
    }
}
