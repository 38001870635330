import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseDataService } from './base-data.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UnloadingSupervisionService {
    pdfDown = new Subject<any>();
    pdfDown$ = this.pdfDown.asObservable();
    pdfImgLoadMap = {};
    pdfIsCanDown = false;
    // pdfIsCanDown = true;
    pdfImgLoadSize = 0;
    // pdfImgLoadSize = 1;
    pdfImgSize = 1;

    apiUrl: string = environment.apiUrl;
    constructor(private baseData: BaseDataService) {}

    /**
     * 获取监卸列表
     */
    getUnloadingSupervisionListApi(params: {}) {
        return this.baseData.get({ url: '/supervisionUnloading/index', params });
    }

    /**
     * 查询合同号
     */
    queryContract(contract_or_sku: string) {
        const params = { contract_or_sku };
        return this.baseData.get({ url: '/loading_supervision/get_can_loading_supervision_sku', params });
    }

    /**
     * 提交创建
     */
    submitCreateUnloadingSupervision(params: any) {
        return this.baseData.post({ url: '/supervisionUnloading/store', params });
    }

    /**
     * 修改监卸
     */
    updateUnloadingSupervision(id: number, params: any) {
        return this.baseData.post({ url: `/supervisionUnloading/${id}/updateCurrent`, params });
    }

    /**
     * 获取详情
     */
    getUnloadingListDetails(id: number) {
        return this.baseData.get({
            url: `/supervisionUnloading/${id}/details
        `,
        });
    }

    /**
     * 确认或撤回
     */
    confirmOrRetract(id: number, type: 'retract' | 'confirm') {
        return this.baseData.post({ url: `/supervisionUnloading/${id}/confirmOrRetract?type=${type}` });
    }

    /**
     * 取消监卸
     */
    cancelUnloadingSupervision(id: number) {
        return this.baseData.post({ url: `/supervisionUnloading/${id}/cancel` });
    }

    /**
     * 获取监卸人员列表
     */
    getGroupUserList(): Observable<any> {
        return this.baseData.get({
            url: '/inspection/select_group_useranddate_list',
        });
    }

    /**
     * 分配监卸人
     */
    assignUnloadingSupervision(id: number, params: any) {
        return this.baseData.post({ url: `/supervisionUnloading/${id}/distributionTask`, params });
    }

    /**
     * pdf预览数据
     */
    getUnloadingSupervisionPdf(id: string) {
        return this.baseData.get({
            url: `/supervisionUnloading/${id}/getPdfData`,
        });
    }
}
