import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseDataService } from './base-data.service';

@Injectable({
    providedIn: 'root',
})
export class HttpBaseService {
    private apiUrl: string = environment.apiUrl;

    constructor(private httpClient: HttpClient, private baseDataService: BaseDataService) {}

    get(url: string, params?: {}, showLoading?: boolean) {
        !showLoading && this.setLoadding(true);
        return this.httpClient.get(this.apiUrl + url, {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                Authorization: this.baseDataService.userInfo.api_token
                    ? `Bearer ${this.baseDataService.userInfo.api_token}`
                    : undefined,
            },
            params: params,
        });
    }

    post(url: string, params?: {}, showLoading?: boolean) {
        !showLoading && this.setLoadding(true);
        return this.httpClient.post(this.apiUrl + url, JSON.stringify(params), {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: this.baseDataService.userInfo.api_token
                    ? `Bearer ${this.baseDataService.userInfo.api_token}`
                    : undefined,
            },
        });
    }

    setLoadding(boo: boolean) {
        this.baseDataService.valueUpdated.next(boo);
    }
}
