import { UnloadingSupervisionService } from 'src/app/services/unloading-supervision.service';
import { PageEffectService } from '../../services/page-effect.service';
import { BaseDataService } from '../../services/base-data.service';
import { Component, OnInit, Input } from '@angular/core';
import { sku } from 'src/app/pages/task-add/task-add.page';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NzMessageService } from 'ng-zorro-antd';
import { AddContractPopupComponent } from 'src/app/component/add-contract-popup/add-contract-popup.component';
import { ModalOptions } from '@ionic/core';
import { MyUtils } from 'src/app/utils/my-utils';
import { AddUnloadingTaskPopupComponent } from '../add-unloading-task-popup/add-unloading-task-popup.component';

@Component({
    selector: 'app-create-unloading-popup',
    templateUrl: './create-unloading-popup.component.html',
    styleUrls: ['./create-unloading-popup.component.scss'],
})
export class CreateUnloadingPopupComponent implements OnInit {
    // 是否有编辑按钮
    @Input() isAllowEdit = false;

    @Input() isEdit = false;

    @Input() set contract(input: any) {
        if (!!input) {
            this._contract = input;
            (this._contract.sku_list ? this._contract.sku_list : this._contract.sku_num).forEach((element, i) => {
                element.index = i;

                element['must_quantity_num'] = element.must_quantity;
                element['quantity_num'] = element.quantity;
            });
        }
    }

    _contract: any;

    @Input() set editDraft(p: any) {
        if (!!p) {
            this.data = p;
        }
    }

    @Input()
    showType: 'input' | 'list';

    @Input()
    id?: number | undefined;

    data: any = {
        cargo_info: [],
        unloading_time: '',
        remarks: '',
    };

    constructor(
        private unloadingSupervisionService: UnloadingSupervisionService,
        public baseData: BaseDataService,
        private es: PageEffectService,
        private msg: NzMessageService,
        public myUtils: MyUtils,
    ) {}

    ngOnInit() {}
    isShowBatchNoWaring = false;
    isShowLadingWaring = false;
    isShowLoadingArea = false;
    isShowDateWaring = false;
    isShowSupervisionType = false;
    enter(type: 'cancel' | 'enter') {
        //('---------', this.data);

        if (type == 'enter') {
            if (!this.data.unloading_time) return (this.isShowDateWaring = true);
            let params = {
                remarks: this.data.remarks,
                unloading_time: this.data.unloading_time,
                cargo_info: this.data.cargo_info.map(el => ({
                    contract_no: el.contract_no,
                    sku: el.sku,
                    sku_chinese_name: el.sku_chinese_name,
                    schedule_user: el.schedule_user_name,
                    purchaser_name: el.purchaser_user_name,
                    estimate_quantity: el.arraying_container_num,
                })),
            };

            if (this.id) {
                this.unloadingSupervisionService.updateUnloadingSupervision(this.id, params).subscribe(res => {
                    if (res.status === 0) {
                        this.msg.error(res.message);
                        return;
                    }
                    this.es.modalCtrl.dismiss({
                        refresh: this.data,
                    });
                });
            } else {
                this.unloadingSupervisionService.submitCreateUnloadingSupervision(params).subscribe(res => {
                    if (res.status === 0) {
                        this.msg.error(res.message);
                        return;
                    }
                    this.es.modalCtrl.dismiss({
                        refresh: this.data,
                    });
                });
            }
        } else {
            if (this.isEdit) {
                this.isEditfn('yes');
                return;
            }
            this.isEditfn('no');
        }
    }

    isEditfn(str: string) {
        this.es.modalCtrl.dismiss({
            refresh: { cancle: true, isEditCancle: str },
        });
    }

    //添加合同
    addContract() {
        let option: ModalOptions = {
            component: AddUnloadingTaskPopupComponent,
            backdropDismiss: false,
            animated: false,
            componentProps: { isAllowEdit: false, showType: 'input', existsDataList: this.data.cargo_info },
        };
        this.es.showModal(option, (data: any) => {
            this.addContractResult(data);
        });
    }

    addContractResult(res: any) {
        if (res === true) {
            return;
        }
        this.data.cargo_info = this.data.cargo_info.concat(res);
        this.addContract();
    }

    deleteContract(index: number) {
        this.data.cargo_info.splice(index, 1);
        if (!this.data.cargo_info.length) {
            this.data.cargo_info = [];
        }
    }

    onChange(date) {
        this.data.unloading_time = date && this.myUtils.dateFormat('YYYY-mm-dd', date);
        this.isShowDateWaring = this.data.unloading_time ? false : true;
    }
}
