import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { contract } from 'src/app/pages/task-add/task-add.page';
import { BaseDataService } from 'src/app/services/base-data.service';
import { NewArrayingContainerService } from 'src/app/services/new-arraying-container.service';
import { SkuTrackService } from 'src/app/services/sku-track.service';

@Component({
    selector: 'app-sku-info',
    templateUrl: './sku-info.component.html',
    styleUrls: ['./sku-info.component.scss'],
})
export class SkuInfoComponent implements OnInit {
    @Input() set contract(input: contract) {
        if (!!input) this._contract = input;
    }
    _contract: contract;
    masterContract: Array<any>;

    splitContract: {
        contract_no: string;
        content: {
            sku: string;
            Count: number;
        }[];
    }[] = [];

    _currentPanels: any[];
    @Input()
    set currentPanels(input: any[]) {
        this._currentPanels = input;
        this.initProgress();
    }
    get currentPanels(): any[] {
        return this._currentPanels;
    }

    progress: { [sku: string]: string } = {};

    @Output() supplementaryNotes = new EventEmitter();
    @Output() showProgressSee = new EventEmitter();

    constructor(
        public baseData: BaseDataService,
        private newArrayingContainerService: NewArrayingContainerService,
        private skuTrackService: SkuTrackService,
    ) {}

    departmentId: number;
    ngOnInit() {
        this.departmentId = +sessionStorage.getItem('department_id');
        this.newArrayingContainerService.getScheduleGetContractDetails(this._contract['id']).subscribe(result => {
            if (result.data.split_contract) {
                for (let splitContractNo in result.data.split_contract) {
                    this.splitContract.push({
                        contract_no: splitContractNo,
                        content: result.data.split_contract[splitContractNo],
                    });
                }
            }
            this.masterContract = result.data.master_contract;

            this.masterContract.map(item => {
                // item.note = item.label === 1 ? 'urgent' : item.label === 2 ? 'suspend' : item.label === 3 ? 'cancel' : undefined;
                item.note =
                    item.label === 1
                        ? 'urgent'
                        : item.label === 2
                        ? 'suspend'
                        : item.label === 3
                        ? 'cancel'
                        : item.label === 4
                        ? 'normal'
                        : item.label === 5
                        ? 'end'
                        : undefined;
                return item;
            });
        });
    }

    initProgress() {
        if (!this.currentPanels) {
            return;
        }
        this.currentPanels.forEach((item, index) => {
            if (index > 0) {
                this.progress[item.name.split(' ')[1]] = item.name.split(' ')[2];
            }
        });
    }
}
