import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Permission } from 'src/app/services/permission.service';
import { PermissionType } from 'src/app/pages/permission/permission.component';
import { NzFormatEmitEvent, NzMessageService, NzTreeNodeOptions } from 'ng-zorro-antd';
import { DataStatisticsService } from 'src/app/services/data-statistics.service';

export interface ReportFiled {
    label: string;
    value: string;
    checked?: boolean;
}
@Component({
    selector: 'app-permission-tree',
    templateUrl: './permission-tree.component.html',
    styleUrls: ['./permission-tree.component.scss'],
})
export class PermissionTreeComponent implements OnInit {
    _permissions: Array<Permission> = [];
    permissionTypeMap: any = PermissionType;
    _permissionListFiled: Array<Permission> = [];
    permissionReport: Array<ReportFiled> = [];
    treePermission: Array<string> = [];
    @Input() nzCheckable: boolean;

    @Input() set permissionListFiled(input: Array<Permission>) {
        if (input && input.length) this._permissionListFiled = this.setListFieldData(input);
    }

    @Input() set permission(input: Array<Permission>) {
        if (input && input.length) this._permissions = input;
        if (!this.nzCheckable) {
            this._permissions = this.setReadonly(this._permissions);
        }
        let fn = this.getSelectedIdForTree();
        let selectedTreeIds: Array<string> = JSON.parse(JSON.stringify(fn(this._permissions)));
        fn = null;
        this.onChange.emit(selectedTreeIds); //将选中的id数组emit出去
    }

    @Input() userId: number;

    @Output() tabIndex = new EventEmitter<number>();

    selectChange(value: any) {
        this.tabIndex.emit(value.index);
    }

    @ViewChild('departmentPostListNodesTreeDom', { static: false }) departmentPostListNodesTreeDom: any;

    submit() {
        const postIdList = [];
        const userArr = {};

        const selectedData: any[] = this.departmentPostListNodesTreeDom.getCheckedNodeList();

        selectedData.forEach(element => {
            if (element.level === 1) {
                postIdList.push(element.key);
            } else {
                const tempKey = element.parentNode.origin.key.toString();
                userArr[tempKey] = userArr[tempKey] || [];
                userArr[tempKey].push({
                    user_id: element.origin.key,
                    user_name: element.origin.title,
                });
            }
        });

        this.dataStatisticsService.postRelationUser(userArr, postIdList, this.userId).subscribe(result => {
            this.msg[result.status ? 'success' : 'error']('数据权限分配' + (result.status ? '成功。' : '失败。'));
        });
    }

    departmentPostListNodes = [];

    departmentPostListNodesExpandChange(event: Required<NzFormatEmitEvent>): void {
        //(event);
        // load child async
        if (event.eventName === 'expand') {
            const node = event.node;
            if (node && node.getChildren().length === 0 && node.isExpanded) {
                this.loadNode(node.origin.key).then(data => {
                    node.addChildren(data);
                });
            }
        }
    }

    initializedUserIdList: number[];
    loadNode(key: string): Promise<NzTreeNodeOptions[]> {
        return new Promise(resolve => {
            this.dataStatisticsService.getHandleUserArrByRoleId(+key).subscribe(result => {
                resolve(
                    result.data.map(element => ({
                        title: element.name,
                        key: element.user_id,
                        isLeaf: true,
                        selectable: false,
                        checked: this.initializedUserIdList.includes(element.user_id),
                    })),
                );
            });
        });
    }

    @Output() onChange: EventEmitter<Array<string>> = new EventEmitter();
    constructor(private dataStatisticsService: DataStatisticsService, private msg: NzMessageService) {}

    ngOnInit() {}

    getRelationUser(id: number) {
        this.departmentPostListNodes = [];

        this.dataStatisticsService.getRelationUser(id).subscribe(result => {
            const postIdList: number[] = Object.keys(result.data.relation_post_user_arr || {}).map(key => +key);
            const expandKeyList: number[] = Object.keys(result.data.relation_user_arr || {}).map(key => +key);

            this.initializedUserIdList = [];
            for (let key in result.data.relation_user_arr) {
                result.data.relation_user_arr[key].forEach(element => {
                    this.initializedUserIdList.push(element.user_id);
                });
            }

            this.dataStatisticsService.departmentPostList().subscribe(async result => {
                for (let key in result.data) {
                    const children = [];
                    for (let element of result.data[key]) {
                        if (element) {
                            let userArrChildren;
                            if (expandKeyList.includes(element.id)) {
                                const result = await this.dataStatisticsService
                                    .getHandleUserArrByRoleId(element.id)
                                    .toPromise();
                                userArrChildren = result.data.map(element => ({
                                    title: element.name,
                                    key: element.user_id,
                                    isLeaf: true,
                                    selectable: false,
                                    checked: this.initializedUserIdList.includes(element.user_id),
                                }));
                            }

                            children.push({
                                title: element.name,
                                key: element.id,
                                selectable: false,
                                checked: postIdList.includes(element.id),
                                expanded: expandKeyList.includes(element.id),
                                children: userArrChildren,
                            });
                        }
                    }

                    this.departmentPostListNodes.push({
                        title: key,
                        key: key,
                        expanded: true,
                        children: children,
                        disableCheckbox: true,
                        selectable: false,
                    });
                }

                this.departmentPostListNodes = [...this.departmentPostListNodes];
            });
        });
    }

    setReadonly(permissions: Permission[]) {
        permissions.forEach(permission => {
            if (permission.children && permission.children.length) {
                this.setReadonly(permission.children);
            } else (permission as any).disableCheckbox = true;
        });
        return permissions;
    }

    /**
     * 设置permission的value和label配合ng-zorro的nz-checkbox-group使用
     * @param permissions
     */
    setListFieldData(permissions: Permission[]): Permission[] {
        permissions.forEach(item => {
            item.children.forEach(child => {
                (child as any).label = (child as any).title;
                (child as any).value = child.key;
            });
        });
        return permissions;
    }

    logReportFiled(e: Permission) {
        this.onChange.emit(this.treePermission.concat(this.getCheckedId() as any)); //将选中的id数组emit出去
        //(e);
        // this.dataBind(e);
    }

    /**
     * 废弃
     * @param e
     */
    dataBind(e: Permission) {
        //将列表字段的key和菜单按钮保持一致
        //通过父级的id找到permission 从而匹配其字段子级
        this._permissions.forEach(permission => {
            if ((permission as any).id === (e as any).id) {
                //嵌套循环
                permission.children.forEach(child => {
                    e.children.forEach(item => {
                        let next = true;
                        if ((child as any).id === (item as any).id) {
                            (child as any).checked = (item as any).checked;
                        } else if (!(child as any).checked) {
                        }
                    });
                });
            }
        });

        this._permissions = [...this._permissions];
    }

    getTreePermission(e: Array<string>, node: any) {
        // if (node.origin.name === 'show-loading-supervision-all-data') {
        //   let isChecked = false;
        //   for (let permission of this._permissions) {
        //     if (permission.name === 'show-loading-supervision-all-data') {
        //       isChecked = permission['checked'];
        //       break;
        //     }
        //   }
        //   const keys = [];
        //   for (let permission of this._permissions) {
        //     if (permission.name === 'loading-supervision') {
        //       keys.push(permission.key);
        //       permission.children.forEach((child) => {
        //         keys.push(child.key);
        //       })
        //     }
        //   }
        // if (isChecked) {
        //     e.push(...keys);
        // } else {
        //     for (const key of keys) {
        //         if (e.indexOf(key) !== -1) {
        //             e = e.splice(e.indexOf(key), 1);
        //         }
        //     }
        // }
        // for (let permission of this._permissions) {
        //     if (permission.name === 'loading-supervision') {
        //         permission['checked'] = isChecked;
        //         permission.children.forEach((child) => {
        //             child['checked'] = isChecked;
        //         })
        //     }
        // }
        // e = Array.from(new Set(e));
        // this._permissions = [...this._permissions];
        // }

        let selectedTreeId: Array<number> = [];
        this.treePermission = e;
        let fn = this.getSelectedIdForTree();
        selectedTreeId = JSON.parse(JSON.stringify(fn(this._permissions)));
        fn = null;
        this.onChange.emit((selectedTreeId as any).concat(this.getCheckedId()));
    }

    //遍历tree将为true的
    getSelectedIdForTree() {
        let value: Array<number> = [];
        return function map(permissions: Array<Permission>) {
            permissions.forEach(permission => {
                (<any>permission).checked && value.push(<any>permission.key);
                permission.children && permission.children.length && map(permission.children);
            });
            return value;
        };
    }

    /**
     * 将permissionListField和permissionReport checked为true的value取出来
     */
    getCheckedId(): Array<number> {
        let value: Array<number> = [],
            listFiled: Array<Permission> = this._permissionListFiled,
            reportFiled: Array<ReportFiled> = this.permissionReport;
        listFiled.forEach((item: Permission) => {
            item.children.forEach(sItem => {
                (<any>sItem).checked && value.push((<any>sItem).id);
            });
        });
        reportFiled.forEach((item: ReportFiled) => {
            item.checked && value.push(<any>item.value);
        });
        return value;
    }
}
