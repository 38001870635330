import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-supplementary-notes-select',
    templateUrl: './supplementary-notes-select.component.html',
    styleUrls: ['./supplementary-notes-select.component.scss'],
})
export class SupplementaryNotesSelectComponent implements OnInit {
    @Input() value: string;
    @Output() select = new EventEmitter();

    constructor() {}

    ngOnInit() {}
}
