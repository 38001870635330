import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-loadding',
    templateUrl: './loadding.component.html',
    styleUrls: ['./loadding.component.scss'],
})
export class LoaddingComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
