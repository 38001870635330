import { PageEffectService } from '../../services/page-effect.service';
import { BaseDataService } from '../../services/base-data.service';
import { Component, OnInit, Input } from '@angular/core';
import { sku } from 'src/app/pages/task-add/task-add.page';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { InspectionService } from 'src/app/services/inspection.service';
import { NzMessageService } from 'ng-zorro-antd';

interface ItemData {
    id: number;
    name: string;
    age: number;
    address: string;
}

@Component({
    selector: 'app-suspend-production-popup',
    templateUrl: 'suspend-production-popup.component.html',
    styleUrls: ['suspend-production-popup.component.scss'],
})
export class SuspendProductionPopupComponent implements OnInit {
    // 是否有编辑按钮
    @Input() isAllowEdit = false;

    isEdit = false;

    loading: boolean = false;
    chipboardDestroy: boolean = true;
    public currentTime: string = this.baseData.utilFn.Format('yyyy-MM-dd');
    use_estimated_loading_time: boolean = true;
    estimated_loading_time: string = this.baseData.utilFn.Format('yyyy-MM-dd');
    timeChange: boolean = false;
    is_new_factory: any = '';
    allChecked: boolean = false;
    @Input() set contract(input: any) {
        if (!!input) {
            this._contract = input;
            (this._contract.sku_list ? this._contract.sku_list : this._contract.sku_num).forEach((element, i) => {
                element.index = i;

                element['must_quantity_num'] = element.must_quantity;
                element['quantity_num'] = element.quantity;
            });
        }
    }

    _contract: any;

    @Input() set applyInspectId(input: number) {
        if (!!input) this._applyInspectId = input;
    }

    @Input() showBtnGroup: any;

    _applyInspectId: number;

    public sku_list: sku[];
    config: SwiperConfigInterface = {};
    @Input()
    showType: 'input' | 'list';

    @Input() showCancel?: boolean = true;

    public data: any = {
        data: {
            inspection_date: this.currentTime,
            estimated_loading_time: this.estimated_loading_time,
            is_new_factory: this.is_new_factory,
            content: [],
        },
    };

    constructor(
        private inspectService: InspectionService,
        public baseData: BaseDataService,
        private effectCtrl: PageEffectService,
        private msg: NzMessageService,
    ) {}

    ngOnInit() {
        this.sku_list =
            this._contract.sku_list && this._contract.sku_list.length
                ? this._contract.sku_list
                : this._contract.sku_num;
        this.data.data.content = JSON.parse(JSON.stringify(this.sku_list));
        this.checkArr.length = 0;

        this.data.data.content.forEach((sku: sku, i) => {
            sku.numIsCom = '';
            sku.isNew = '';
            sku.group = '';
            sku.is_need_drop_test = sku.is_need_drop_test ? sku.is_need_drop_test : '1';
            sku.has_strap = sku.has_strap ? sku.has_strap : '';
            sku.is_need_sample = sku.is_need_sample ? sku.is_need_sample : '';
            sku.need_bring_back_instructor = sku.need_bring_back_instructor ? sku.need_bring_back_instructor : '';
            sku.news_or_return_product = sku.news_or_return_product ? sku.news_or_return_product : '';
            sku.photo = sku.photo && sku.photo.length ? sku.photo : [];
            sku.stop_reason = sku.stop_reason ? sku.stop_reason : '';
            // if (sku.is_stop === 1) {
            //     sku.checked = true
            //     this.checkArr.push(sku)

            //     if (this.checkArr.length === this.data.data.content.length) {
            //         this.allChecked = true
            //     }
            // }
            sku.checked = sku.is_stop === 1 ? true : false;
        });

        this.checkArr = this.data.data.content;
        const isShow = this.checkArr.every(item => {
            return item.is_stop === 1;
        });

        this.allChecked = isShow ? true : false;

        this.clickAll = true;
        setTimeout(() => {
            this.clickAll = false;
        }, 0);

        this.baseData.printDebug && console.log(this.data.data);
    }

    enter(type: 'cancel' | 'enter') {
        if (type == 'enter') {
            let upDateSkuData: any[] = [];

            this.checkArr.forEach((sku: sku, i: number) => {
                upDateSkuData.push({
                    sku: sku.sku,
                    is_stop: sku.is_stop,
                    stop_reason: sku.stop_reason,
                });
            });

            this.effectCtrl.modalCtrl.dismiss({
                refresh: upDateSkuData,
            });
        } else {
            this.effectCtrl.modalCtrl.dismiss({
                refresh: true,
            });
        }
    }

    regValid(e: any) {
        if (!/^(?!0+(?:\0+)?$)(?:[1-9]\d*|0)(?:\\d{1,2})?$/.test(e.target.value)) {
            e.target.value = null;
        }
    }

    getAryToObj(obj: any) {
        let ary = [];
        for (var key in obj) {
            ary.push(obj[key]);
        }
        return ary;
    }

    onscroll(e: any) {
        document.getElementById('fixed-thead').setAttribute('style', `transform:translateY(${e.target.scrollTop}px)`);
    }

    checkArr: Array<any> = [];

    flag = false;
    selectChange(e, p) {
        if (this.clickAll) {
            return;
        }

        if (e.detail.checked) {
            // this.checkArr.push(p)

            this.checkArr.forEach(item => {
                if (item == p) {
                    item.is_stop = 1;
                    return;
                }
            });
            const isAll = this.data.data.content.every(item => {
                return item.checked;
            });
            if (isAll) {
                this.allChecked = true;
                this.flag = true;
                // setTimeout(() => {
                //     this.flag = false
                // }, 0);
            }
        } else {
            // this.checkArr = this.checkArr.filter(item => {
            //     return item != p
            // })
            this.checkArr.forEach(item => {
                if (item == p) {
                    item.is_stop = 0;
                    return;
                }
            });

            if (this.allChecked) {
                this.allChecked = false;
                this.flag = true;
                // setTimeout(() => {
                //     this.flag = false
                // }, 0);
            }
        }
    }

    clickAll = false;
    allCheck(e: any) {
        if (this.flag) {
            this.flag = false;
            return;
        }

        this.data.data.content.forEach(res => ((res as any).checked = e.detail.checked));
        if (e.detail.checked) {
            // this.checkArr = this.data.data.content
            this.checkArr.forEach(item => {
                item.is_stop = 1;
            });
        } else {
            // this.checkArr = []
            this.checkArr.forEach(item => {
                item.is_stop = 0;
            });
        }

        this.clickAll = true;
        setTimeout(() => {
            this.clickAll = false;
        }, 0);
    }
}

export interface upDateSkuData {
    complete: number | string;
    description: string[];
    quantity: number;
    sku: string;
    isNew: number | string;
    group?: any;
    photo: string[];
    logo_desc?: string;
    is_need_drop_test?: number;
    has_strap?: number;
    is_need_sample?: number;
    estimated_loading_time?: string;
    need_bring_back_instructor?: any;
    news_or_return_product?: number;
    must_quantity?: number;
    warehouse?: 'USA' | 'AUE';
}
