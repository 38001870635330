import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-no-data-show',
    templateUrl: './no-data-show.component.html',
    styleUrls: ['./no-data-show.component.scss'],
})
export class NoDataShowComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
