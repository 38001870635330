import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-skeleton-img',
    templateUrl: './skeleton-img.component.html',
    styleUrls: ['./skeleton-img.component.scss'],
})
export class SkeletonImgComponent implements OnInit {
    @Input() width: string;
    @Input() height: string;
    @Input() url: string;

    isCloseSkeleton = false;

    constructor() {}

    ngOnInit() {}

    ngAfterViewInit() {
        const imgEl = document.getElementById(this.url);
        this.imgLoad(imgEl, () => {
            this.isCloseSkeleton = true;
        });
    }

    imgLoad(img, callback) {
        const timer = setInterval(function() {
            if (img.complete) {
                callback(img);
                clearInterval(timer);
            }
        }, 50);
    }
}
