import { Injectable } from '@angular/core';
import { BaseDataService } from './base-data.service';
import { Observable } from 'rxjs';
import { Response } from './inspection.service';
import { map } from 'rxjs/operators';
import { InspectAppInfo } from '../pages/examine-detail/examine-detail.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface AdviseParams {
    id: number;
    contents: string;
    img?: string;
    apply_inspection_no?: number;
    advise_img?: any;
}

export interface Advise {
    advise_img?: string;
    advise_video?: string;
    apply_inspection_no?: string;
    contents: string;
    created_at: string;
    id: number;
    inspection_task_pre_id?: number;
    path?: number;
    pid?: number;
    review_type?: string;
    son?: Advise[];
    updated_at?: string;
    user?: { id: number; name: string };
    user_id?: number;
    sku?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ExamineService {
    apiUrl: string = environment.apiUrl;

    constructor(private baseData: BaseDataService, private http: HttpClient) {}

    //获取验货审核内容
    getReviewAdviseData(
        apply_inspection_no: number,
    ): Observable<{
        data: Advise[];
        info: InspectAppInfo[];
        is_reviewer: boolean;
        review_status: Array<{ key: number; value: string }>;
    }> {
        return this.baseData
            .get({
                url: '/task/get_inspection_task_review_advise_data',
                params: { apply_inspection_no },
            })
            .pipe(map(res => res.data));
    }

    //添加验货审核内容
    addReviewAdvise(params: AdviseParams): Observable<Response<Advise[]>> {
        return this.baseData.post({ url: '/task/add_inspection_task_review_advise_data', params });
    }

    //修改验货审核内容
    modifyReviewAdvise(params: AdviseParams): Observable<Response<null>> {
        return this.baseData.post({ url: '/task/edit_inspection_task_review_advise_data', params });
    }

    //删除验货审核内容
    deleteReviewAdvise(params: { id: number }): Observable<Response<Advise[]>> {
        return this.baseData.get({ url: '/task/del_inspection_task_review_advise_data', params });
    }

    //审核 添加总结 文字
    addReviewSummaryText(params: AdviseSummary) {
        return this.baseData.post({ url: '/task/add_inspection_task_review_summary_desc', params });
    }

    // 上传数据审核回复信息
    addInspectionTaskReplyDesc(
        apply_inspection_no: string,
        contract_no: string,
        sku: string,
        comment_id: number,
        review_summary_desc: string,
        file: any,
    ) {
        const formData = new FormData();
        formData.append('apply_inspection_no', apply_inspection_no);
        formData.append('contract_no', contract_no);
        formData.append('sku', sku);
        formData.append('review_summary_desc', review_summary_desc);
        formData.append('id', comment_id.toString());

        if (file) {
            formData.append('file', file, file.name);
        }

        return this.http.post(this.apiUrl + '/task/add_inspection_task_reply_desc', formData, {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                Accept: 'multipart/form-data',
                Authorization: this.baseData.userInfo.api_token
                    ? `Bearer ${this.baseData.userInfo.api_token}`
                    : undefined,
            },
        });
    }

    //审核 添加总结 图片/视频
    addReviewSummaryMedia(params: AdviseSummary) {
        return this.baseData.post({ url: '/task/add_inspection_task_review_summary_pic_video', params });
    }

    //删除 总结 图片/视频
    removeReviewSummaryMedia(params: AdviseSummary) {
        return this.baseData.post({ url: '/task/del_inspection_task_review_summary_pic_video', params });
    }

    //撤销验货申请功能 数据审核
    resetInspectTaskSku(params: { apply_inspection_no: string; contract_no: string; sku: string }) {
        return this.baseData.get({ url: '/task/reset_inspection_task_sku', params });
    }

    /**
     * 返工计划新增
     * @param sku
     * @param contract_no
     * @param apply_inspection_no
     * @param rework_plan
     * @returns
     */
    addInspectionReworkPlan(
        sku: string,
        contract_no: string,
        apply_inspection_no: string,
        rework_plan: {
            plan_desc: {
                problem_no: number;
                rework_type: string;
                plan_desc: string;
                status: number;
                rework_plan_video: string;
            }[];
        },
        sku_chinese: string,
        manufacturer: string,
    ) {
        const params = { sku, contract_no, apply_inspection_no, rework_plan, sku_chinese, manufacturer };

        return this.baseData.post({ url: '/task/add_inspection_rework_plan', params });
    }

    /**
     * 返工计划查询
     * @param apply_inspection_no
     * @param contract_no
     * @param sku
     */
    getInspectionReworkPlan(apply_inspection_no: string, contract_no: string, sku: string) {
        const params = {
            apply_inspection_no,
            contract_no,
            sku,
        };

        return this.baseData.get({ url: '/task/get_inspection_rework_plan', params });
    }

    /**
     * 返工计划变更
     * @param sku
     * @param contract_no
     * @param apply_inspection_no
     * @param rework_plan
     */
    postInspectionReworkPlan(
        sku: string,
        contract_no: string,
        apply_inspection_no: string,
        rework_plan: {
            plan_desc: {
                problem_no: number;
                rework_type: string;
                plan_desc: string;
            }[];
        },
    ) {
        const params = { sku, contract_no, apply_inspection_no, rework_plan };

        return this.baseData.post({ url: '/task/post_inspection_rework_plan', params });
    }

    /**
     * 返工计划视频删除
     * @param apply_inspection_no
     * @param contract_no
     * @param sku
     * @param problem_no
     * @param file_name
     */
    delInspectionReworkPlanVideo(
        apply_inspection_no: string,
        contract_no: string,
        sku: string,
        problem_no: number,
        file_name: string,
    ) {
        const params = {
            apply_inspection_no,
            contract_no,
            sku,
            problem_no,
            file_name: file_name,
        };

        return this.baseData.post({ url: '/task/del_inspection_rework_plan_video', params });
    }

    delInspectionReworkPlanImg(
        apply_inspection_no: string,
        contract_no: string,
        sku: string,
        problem_no: number,
        file_name: string,
    ) {
        const params = {
            apply_inspection_no,
            contract_no,
            sku,
            problem_no,
            fileName: file_name,
            fileType: 'pic',
        };

        return this.baseData.post({ url: '/task/del_inspection_task_rework_plan_pic_video', params });
    }

    imgFileListUpload(
        apply_inspection_no: string,
        contract_no: string,
        sku: string,
        problem_no: number,
        fileList: File[],
    ) {
        const formData = new FormData();

        formData.append('apply_inspection_no', apply_inspection_no);
        formData.append('contract_no', contract_no);
        formData.append('sku', sku);
        formData.append('problem_no', problem_no + '');
        Array.from(fileList).forEach((file, index) => {
            formData.append(`img${index + 1}`, file, file.name);
        });

        return this.http.post(this.apiUrl + '/task/add_inspection_task_rework_plan_pic_video', formData, {
            headers: {
                Accept: 'multipart/form-data',
                Authorization: this.baseData.userInfo.api_token
                    ? `Bearer ${this.baseData.userInfo.api_token}`
                    : undefined,
            },
        });
    }
}

export interface AdviseSummary {
    review_summary_desc?: string;
    apply_inspection_no: string;
    sku: string;
    id: number;
    contract_no?: string;
}
