import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataStatisticsService } from 'src/app/services/data-statistics.service';

@Component({
    selector: 'app-select-user',
    templateUrl: './select-user.component.html',
    styleUrls: ['./select-user.component.scss'],
})
export class SelectUserComponent implements OnInit {
    @Input() placeholder: string;
    @Output() selectedIdList = new EventEmitter<number[]>();
    @Output() selectedNameList = new EventEmitter<string[]>();
    @Input() multiple = false;

    private _defaultNameList: string[];
    @Input()
    set defaultNameList(defaultNameList: string[]) {
        this._defaultNameList = defaultNameList;
        if (
            this._defaultNameList &&
            this._defaultNameList.length > 0 &&
            this.currentNameList.toString() != this._defaultNameList.toString()
        ) {
            this.searchMath(this._defaultNameList);
        }
    }
    get defaultNameList() {
        return this._defaultNameList;
    }

    currentNameList = [];

    ids: number[];
    data: { id: number; name: string }[] = [];

    isLoading = false;

    constructor(private dataStatisticsService: DataStatisticsService) {}

    ngOnInit() {}

    onSearch(val: string) {
        if (!this.multiple && val === '') {
        } else {
            this.isLoading = true;
            this.dataStatisticsService.getUserByName(val).subscribe(result => {
                this.isLoading = false;
                this.data = result.data;
            });
        }
    }

    searchMath(nameList: string[]) {
        this.isLoading = true;
        const promiseList = [];
        for (let name of nameList) {
            promiseList.push(this.dataStatisticsService.getUserByName(name).toPromise());
        }

        Promise.all(promiseList).then(result => {
            const temp = [];
            result.forEach(item => {
                temp.push(...item.data);
            });
            this.ids = temp.map(item => item.id);
            this.isLoading = false;
            this.data = temp;
        });
    }

    change(idList: number[]) {
        if (!idList || idList.length === 0) {
            this.selectedIdList.emit([]);
            this.selectedNameList.emit([]);
            return;
        }

        this.dataStatisticsService.getUserByName('').subscribe(result => {
            this.data = result.data;
            this.currentNameList = this.data.filter(item => idList.includes(item.id)).map(item => item.name);
            this.selectedIdList.emit(idList);
            this.selectedNameList.emit(this.currentNameList);
        });
    }
}
