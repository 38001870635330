import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MyUtils } from '../utils/my-utils';
import { BaseDataService } from './base-data.service';

@Injectable({
    providedIn: 'root',
})
export class DataStatisticsService {
    apiUrl: string = environment.apiUrl;

    constructor(private baseData: BaseDataService, private myUtils: MyUtils, private http: HttpClient) {}

    /**
     * 查看部门岗位统计数据
     * @returns
     */
    getContractCountData() {
        return this.baseData.get({ url: '/data_statistics/get_contract_count_data' });
    }

    /**
     * 查看个人时效性数据
     * @returns
     */
    getContractTimelinessAllData() {
        return this.baseData.get({ url: '/data_statistics/get_contract_timeliness_all_data' });
    }

    /**
     * 查看部门岗位待办数据
     * @returns
     */
    getWaitingProcessingList() {
        return this.baseData.get({ url: '/data_statistics/get_waiting_processing_list' });
    }

    /**
     * 获取质检助理审核（初审）/质检主管（经理）审核（终审）/数据
     * @returns
     */
    getQualityExamineAllData() {
        return this.baseData.get({ url: '/data_statistics/get_quality_examine_all_data' });
    }

    /**
     * 查看质检助理进度信息（主管，经理）
     * @returns
     */
    getQualityAssistantContractCountData() {
        return this.baseData.get({ url: '/data_statistics/get_quality_assistant_contract_count_data' });
    }

    /**
     * 查看查看采购/跟单/质检部门时效性数据
     * @returns
     */
    getDepartmentTimelinessAllData() {
        return this.baseData.get({ url: '/data_statistics/get_department_timeliness_all_data' });
    }

    /**
     * 查看采购/跟单/质检部门统计数据
     * @returns
     */
    getDepartmentCountAllData() {
        return this.baseData.get({ url: '/data_statistics/get_department_count_all_data' });
    }

    /**
     * 更新部门规章
     * @param theme
     * @param content
     * @param id
     * @returns
     */
    updateDepartmentalRegulations(theme: string, content: string, id: number) {
        let params = {
            theme,
            content,
            id,
        };
        params = JSON.parse(JSON.stringify(params));
        return this.baseData.post({ url: '/data_statistics/update_departmental_regulations', params });
    }

    /**
     * 上传部门规章
     * @param theme
     * @param content
     * @returns
     */
    postDepartmentalRegulations(theme: string, content: string) {
        const params = {
            theme,
            content,
        };
        return this.baseData.post({ url: '/data_statistics/post_departmental_regulations', params });
    }

    /**
     * 查看当前部门规章
     * @returns
     */
    getDepartmentalRegulations() {
        return this.baseData.get({ url: '/data_statistics/get_departmental_regulations' });
    }

    /**
     * 获取当前用户数据
     * @returns
     */
    getOwnTaskManageData() {
        return this.baseData.get({ url: '/task_manage/get_own_task_manage_data' });
    }

    /**
     * 获取用户列表
     * @returns
     */
    getUserByName(userName: string) {
        const params = {
            user_name: userName,
        };
        return this.baseData.get({ url: '/task_manage/get_user_by_name', params }, true);
    }

    /**
     * 任务列表
     * @param paginate
     * @param page
     * @param sort_key
     * @param sort_value
     * @param type
     * @param status
     * @param relevant
     * @returns
     */
    getTaskManageList(
        paginate: number,
        page: number,
        sort_key: string,
        sort_value: string,
        type: number,
        status: number,
        relevant: string,
    ) {
        let params = {
            paginate,
            page,
            sort_key,
            sort_value,
            type,
            status,
            relevant,
        };

        params = JSON.parse(JSON.stringify(params));

        return this.baseData.get({ url: '/task_manage/get_task_manage_list', params });
    }

    /**
     * 提交任务
     * @returns
     */
    postTaskManageData(
        confirmed_by_id: number,
        type: number,
        priority: number,
        task_title: string,
        end_time: Date,
        confirmed_by: string,
        task_describe?: string,
        acceptance_criteria?: string,
        file?: any,
    ) {
        let formData = new FormData();
        formData.append('confirmed_by_id', confirmed_by_id.toString());
        formData.append('confirmed_by', confirmed_by);
        formData.append('type', type.toString());
        formData.append('priority', priority.toString());
        formData.append('task_title', task_title);
        formData.append('end_time', this.myUtils.dateFormat('YYYY-mm-dd', end_time));
        formData.append('task_describe', task_describe || '');
        formData.append('acceptance_criteria', acceptance_criteria || '');
        if (file) {
            formData.append('file', file as any, file.name);
        }

        return this.http.post(this.apiUrl + '/task_manage/post_task_manage_data', formData, {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                Accept: 'multipart/form-data',
                Authorization: this.baseData.userInfo.api_token
                    ? `Bearer ${this.baseData.userInfo.api_token}`
                    : undefined,
            },
        });
    }

    /**
     * 上传附件（小于30M）
     * @param task_no
     * @param file
     * @returns
     */
    uploadFileOfTask(task_no: string, file: any) {
        let formData = new FormData();
        formData.append('task_no', task_no);
        formData.append('file', file as any, file.name);

        return this.http.post(this.apiUrl + '/task_manage/upload_file_of_task', formData, {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                Accept: 'multipart/form-data',
                Authorization: this.baseData.userInfo.api_token
                    ? `Bearer ${this.baseData.userInfo.api_token}`
                    : undefined,
            },
        });
    }

    /**
     * 任务管理任务详情
     * @param id
     * @returns
     */
    getTaskInfo(id: number) {
        return this.baseData.get({ url: '/task_manage/get_task_info', params: { id } });
    }

    /**
     * 获取任务类型
     * @returns
     */
    getTaskType() {
        return this.baseData.get({ url: '/task_manage/get_task_type' });
    }

    /**
     * 变更任务（确认之前）
     * @param id
     * @param type
     * @param priority
     * @param task_title
     * @param task_describe
     * @param acceptance_criteria
     * @param end_time
     * @param confirmed_by
     * @returns
     */
    updateTaskData(
        id: number,
        type: number,
        priority: number,
        task_title: string,
        task_describe: string,
        acceptance_criteria: string,
        end_time: string,
        confirmed_by: string,
        confirmed_by_id: number,
        remarks: string,
        file: any,
    ) {
        let formData = new FormData();

        formData.append('id', id.toString());
        formData.append('type', type.toString());
        formData.append('priority', priority.toString());
        formData.append('task_title', task_title);
        formData.append('task_describe', task_describe || '');
        formData.append('acceptance_criteria', acceptance_criteria || '');
        formData.append('end_time', end_time);
        formData.append('confirmed_by', confirmed_by);
        formData.append('confirmed_by_id', confirmed_by_id.toString());
        formData.append('remarks', remarks || '');

        if (file) {
            formData.append('file', file as any, file.name);
        }

        return this.http.post(this.apiUrl + '/task_manage/update_task_of_before_confirm', formData, {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                Accept: 'multipart/form-data',
                Authorization: this.baseData.userInfo.api_token
                    ? `Bearer ${this.baseData.userInfo.api_token}`
                    : undefined,
            },
        });
    }

    /**
     * 变更任务（确认之后）
     * @param id
     * @param type
     * @param priority
     * @param task_title
     * @param task_describe
     * @param acceptance_criteria
     * @param end_time
     * @param assigned_person
     * @param cc_people_id
     * @param cc_people_name
     * @returns
     */
    updateTaskOfAfterConfirm(
        id: number,
        type: number,
        priority: number,
        task_title: string,
        task_describe: string,
        acceptance_criteria: string,
        end_time: string,
        assigned_person: string,
        cc_people_id: number[],
        cc_people_name: string[],
        remarks: string,
        file: any,
    ) {
        let formData = new FormData();

        formData.append('id', id.toString());
        formData.append('type', type.toString());
        formData.append('priority', priority.toString());
        formData.append('task_title', task_title);
        formData.append('task_describe', task_describe || '');
        formData.append('acceptance_criteria', acceptance_criteria || '');
        formData.append('end_time', end_time);
        formData.append('assigned_person', assigned_person);
        formData.append('cc_people_id', cc_people_id.toString());
        formData.append('cc_people_name', cc_people_name.toString());
        formData.append('remarks', remarks || '');

        if (file) {
            formData.append('file', file as any, file.name);
        }

        return this.http.post(this.apiUrl + '/task_manage/update_task_of_after_confirm', formData, {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                Accept: 'multipart/form-data',
                Authorization: this.baseData.userInfo.api_token
                    ? `Bearer ${this.baseData.userInfo.api_token}`
                    : undefined,
            },
        });
    }

    /**
     * 删除附件（图片）
     * @param taskNo
     * @param filename
     * @param type
     * @returns
     */
    delFileOfTask(taskNo: string, filename: string, type = 'enclosure') {
        const params = {
            task_no: taskNo,
            type,
            filename,
        };
        return this.baseData.post({ url: '/task_manage/del_file_of_task', params });
    }

    /**
     * 删除附件（除图片）
     * @param taskNo
     * @param filename
     * @param type
     * @returns
     */
    delManageFileOfTask(taskNo: string, filename: string, type = 'enclosure') {
        const params = {
            task_no: taskNo,
            type,
            filename,
        };
        return this.baseData.post({ url: '/task_manage/del_manage_file_of_task', params });
    }

    /**
     * 确认任务
     * @param assigned_person
     * @param cc_people_id
     * @param remarks
     * @param id
     * @param cc_people_name
     * @returns
     */
    postConfirmTask(
        assigned_person: string,
        cc_people_id: number[],
        cc_people_name: string[],
        remarks: string,
        id: number,
    ) {
        const params = {
            assigned_person,
            id,
        };

        params['cc_people_id'] = cc_people_id ? cc_people_id.toString() : '';
        params['cc_people_name'] = cc_people_name ? cc_people_name.toString() : '';
        params['remarks'] = remarks || '';

        return this.baseData.post({ url: '/task_manage/post_confirm_task', params });
    }

    /**
     * 完成任务
     * @param id
     * @param complete_time
     * @param remarks
     * @returns
     */
    postCompleteTask(id: number, complete_time: string, remarks: string) {
        const params = {
            id,
            complete_time,
            remarks: remarks || '',
        };
        return this.baseData.post({ url: '/task_manage/post_complete_task', params });
    }

    /**
     * 关闭任务
     * @param id
     * @param close_reason
     * @param status
     * @param remarks
     * @returns
     */
    postCloseTask(id: number, close_reason: string, status: number, remarks: string) {
        const params = {
            id,
            close_reason,
            status,
        };

        params['remarks'] = remarks || '';

        return this.baseData.post({ url: '/task_manage/post_close_task', params });
    }

    /**
     * 任务管理任务统计
     * @param keyword
     * @returns
     */
    taskManageTaskCount(keyword: 'priority' | 'type' | 'status' | 'create' | 'completed') {
        return this.baseData.get({ url: '/task_manage/task_manage_task_count', params: { keyword } });
    }

    /**
     * 下载任务excel
     * @param paginate
     * @param page
     * @param sort_key
     * @param sort_value
     * @param type
     * @param status
     * @param relevant
     * @returns
     */
    downTaskManageDataExcel(
        paginate: number,
        page: number,
        sort_key: string,
        sort_value: string,
        type: number,
        status: number,
        relevant: string,
    ) {
        let params = {
            paginate,
            page,
            sort_key,
            sort_value,
            type,
            status,
            relevant,
        };

        params = JSON.parse(JSON.stringify(params));

        let str = '';
        const keys = Object.keys(params);
        keys.forEach(key => {
            str += `&${key}=${params[key]}`;
        });

        const token = this.baseData.userInfo.api_token ? `${this.baseData.userInfo.api_token}` : undefined;
        let url = `${this.apiUrl}/task_manage/down_task_manage_data_excel?api_token=${token}${str}`;
        location.href = url;
    }

    /**
     * 文件下载
     * @param task_no
     */
    downTaskEnclosure(task_no: string) {
        const params = {
            task_no,
        };

        let str = '';
        const keys = Object.keys(params);
        keys.forEach(key => {
            str += `&${key}=${params[key]}`;
        });

        const token = this.baseData.userInfo.api_token ? `${this.baseData.userInfo.api_token}` : undefined;
        let url = `${this.apiUrl}/task_manage/down_task_enclosure?api_token=${token}${str}`;
        location.href = url;
    }

    /**
     * 获取所有部门及旗下岗位
     * @returns
     */
    departmentPostList() {
        return this.baseData.get({ url: '/roleNew/department_post_list' });
    }

    /**
     * 获取岗位旗下所有人员
     * @param roleId 岗位ID
     */
    getHandleUserArrByRoleId(roleId: number) {
        return this.baseData.get({ url: '/user/get_handle_user_arr_by_role_id', params: { role_id: roleId } });
    }

    /**
     * 更新角色关联员工
     * @param user_arr
     * @param post_id
     * @returns
     */
    postRelationUser(user_arr: any, post_id: number[], user_id: number) {
        const params = {
            user_arr,
            post_id,
            user_id,
        };

        return this.baseData.post({ url: '/user/post_relation_user', params });
    }

    /**
     * 获取角色关联员工信息
     * @returns
     */
    getRelationUser(user_id: number) {
        return this.baseData.get({ url: '/user/get_relation_user', params: { user_id } });
    }

    /**
     * 获取合同及旗下sku跟踪进度
     * @param contract_id
     * @returns
     */
    getContractOrSkuProgress(contract_id: number, inspectionStatus?: number) {
        return this.baseData.get({
            url: '/progress/get_contract_or_sku_progress',
            params: { contract_id, inspection_status: inspectionStatus },
        });
    }

    /**
     * 获取前期物料安排贴
     * @returns
     */
    getProphaseMaterial() {
        return this.baseData.get({ url: '/progress/get_prophase_material' });
    }

    /**
     * 合同营业执照/账目信息确认(进度跟新)
     */
    postLicenseAccountConfirm(contract_id: number, license_account_confirm: number) {
        const params = { contract_id, license_account_confirm };
        return this.baseData.post({ url: '/progress/post_license_account_confirm', params });
    }

    /**
     * 提交预计完成时间
     * @param contract_id
     * @param estimate_complete_date
     * @returns
     */
    postEstimateComTime(contract_id: number, estimate_complete_date: Date) {
        const params = {
            contract_id,
            estimate_complete_date: this.myUtils.dateFormat('YYYY-mm-dd', estimate_complete_date),
        };
        return this.baseData.post({ url: '/progress/post_estimate_com_time', params });
    }

    /**
     * 可出运确认
     * @param contract_id
     * @param sku
     * @param can_be_shipped_confirm
     * @returns
     */
    postCanBeShippedConfirm(contract_id: number, sku: string, can_be_shipped_confirm: number) {
        const params = { contract_id, sku, can_be_shipped_confirm };
        return this.baseData.post({ url: '/progress/post_can_be_shipped_confirm', params });
    }

    /**
     * 大货样确认
     * @param contract_id
     * @param sku
     * @param large_sample_confirm
     * @returns
     */
    postLargeSampleConfirm(contract_id: number, sku: string, large_sample_confirm: number, is_need: number) {
        const params = { contract_id, sku, large_sample_confirm, is_need };
        return this.baseData.post({ url: '/progress/post_large_sample_confirm', params });
    }

    /**
     * 生产已完成确认
     * @param contract_id
     * @param sku
     * @param production_completion_confirm
     * @returns
     */
    postProComConfirm(contract_id: number, sku: string, production_completion_confirm: number) {
        const params = { contract_id, sku, production_completion_confirm };
        return this.baseData.post({ url: '/progress/post_pro_com_confirm', params });
    }

    /**
     * 是否有说明书确认
     * @param contract_id
     * @param sku
     * @param have_instructions
     * @returns
     */
    postHaveInstructions(contract_id: number, sku: string, have_instructions: number, is_have: number) {
        const params = { contract_id, sku, have_instructions, is_have };
        return this.baseData.post({ url: '/progress/post_have_instructions', params });
    }

    /**
     * 已安排生产确认
     * @param contract_id
     * @param sku
     * @param scheduled_btf_confirm
     * @param scheduled_btf_remarks
     * @returns
     */
    postScheduledBtfConfirm(
        contract_id: number,
        sku: string,
        scheduled_btf_confirm: number,
        scheduled_btf_remarks: string,
    ) {
        const params = { contract_id, sku, scheduled_btf_confirm, scheduled_btf_remarks };
        return this.baseData.post({ url: '/progress/post_scheduled_btf_confirm', params });
    }

    /**
     * 前期物料已备料确认
     * @param contract_id
     * @param sku
     * @param prepared_material_confirm
     * @param prepared_material_remarks
     * @returns
     */
    postPedMaterialConfirm(
        contract_id: number,
        sku: string,
        prepared_material_confirm: number,
        prepared_material_remarks: string,
    ) {
        const params = { contract_id, sku, prepared_material_confirm, prepared_material_remarks };
        return this.baseData.post({ url: '/progress/post_ped_material_confirm', params });
    }

    /**
     * 前期物料确认
     * @param contract_id
     * @param sku
     * @param material_confirm
     * @param prophase_material
     * @returns
     */
    postMaterialConfirm(contract_id: number, sku: string, material_confirm: number, prophase_material: number[]) {
        const params = { contract_id, sku, material_confirm, prophase_material };
        return this.baseData.post({ url: '/progress/post_material_confirm', params });
    }

    /**
     * 箱唛确认
     * @param contract_id
     * @param shipping_mark_confirm
     * @param shipping_mark_remarks
     * @returns
     */
    postShippingMarkConfirm(contract_id: number, shipping_mark_confirm: number, shipping_mark_remarks: string) {
        const params = { contract_id, shipping_mark_confirm, shipping_mark_remarks };
        return this.baseData.post({ url: '/progress/post_shipping_mark_confirm', params });
    }

    /**
     * 验货需求确认
     * @param contract_id
     * @param inspection_requirements_confirm
     * @returns
     */
    postInspectionRqConfirm(
        contract_id: number,
        inspection_requirements_confirm: number,
        file: any,
        contract_no: string,
    ) {
        let formData = new FormData();
        formData.append('contract_id', contract_id.toString());
        formData.append('inspection_requirements_confirm', inspection_requirements_confirm.toString());
        formData.append('type', 'inspection_requirements_file');
        formData.append('contract_no', contract_no.toString());
        if (file && file.name) {
            formData.append('file', file as any, file.name);
        }

        return this.http.post(this.apiUrl + '/progress/post_inspection_rq_confirm', formData, {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                Accept: 'multipart/form-data',
                Authorization: this.baseData.userInfo.api_token
                    ? `Bearer ${this.baseData.userInfo.api_token}`
                    : undefined,
            },
        });
    }

    /**
     * 大货生产注意事项确认
     * @param contract_id
     * @param points_for_attention_confirm
     * @returns
     */
    postPointsFattentionConfirm(
        contract_id: number,
        points_for_attention_confirm: number,
        file: any,
        contract_no: string,
    ) {
        let formData = new FormData();
        formData.append('contract_id', contract_id.toString());
        formData.append('points_for_attention_confirm', points_for_attention_confirm.toString());
        formData.append('type', 'points_for_attention_file');
        formData.append('contract_no', contract_no.toString());

        if (file && file.name) {
            formData.append('file', file as any, file.name);
        }

        return this.http.post(this.apiUrl + '/progress/post_points_fattention_confirm', formData, {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                Accept: 'multipart/form-data',
                Authorization: this.baseData.userInfo.api_token
                    ? `Bearer ${this.baseData.userInfo.api_token}`
                    : undefined,
            },
        });
    }

    /**
     * 合同正文已收确认
     * @param contract_id
     * @param contract_text_received_confirm
     * @returns
     */
    postCtextReceivedConfirm(contract_id: number, contract_text_received_confirm: number) {
        const params = { contract_id, contract_text_received_confirm };
        return this.baseData.post({ url: '/progress/post_ctext_received_confirm', params });
    }

    /**
     * 获取合同备注
     * @param contract_id
     * @returns
     */
    getContractRemarks(contract_id: number) {
        return this.baseData.get({ url: '/progress/get_contract_remarks', params: { contract_id } }, true);
    }

    /**
     * 提交合同备注
     * @param contract_id
     * @param remark
     * @returns
     */
    postContractRemarks(contract_id: number, remark: string) {
        const params = { contract_id, remark };
        return this.baseData.post({ url: '/progress/post_contract_remarks', params });
    }

    /**
     * 删除合同备注
     * @param contract_id
     * @param index
     * @returns
     */
    delContractRemarks(contract_id: number, index: number) {
        const params = { contract_id, index };
        return this.baseData.post({ url: '/progress/del_contract_remarks', params });
    }

    /**
     * 获取免检sku列表
     * @param apply_user_id
     * @param sku
     * @param status
     * @param apply_date_start
     * @param apply_date_end
     */
    getNotNcSkuList(
        apply_user_id: number,
        sku: string,
        status: number,
        apply_date_start: Date,
        apply_date_end: Date,
        paginate: number,
        page: number,
    ) {
        let params = { sku, paginate, page };

        if (apply_user_id) {
            params['apply_user_id'] = apply_user_id;
        }

        if (status) {
            params['status'] = status;
        }

        if (apply_date_start) {
            params['apply_date_start'] = this.myUtils.dateFormat('YYYY-mm-dd', apply_date_start);
        }

        if (apply_date_end) {
            params['apply_date_end'] = this.myUtils.dateFormat('YYYY-mm-dd', apply_date_end);
        }

        params = JSON.parse(JSON.stringify(params));

        return this.baseData.get({ url: '/nnCheck/get_not_nc_sku_list', params });
    }

    /**
     * 免检sku启用/禁用
     * @param id
     * @param is_enable
     * @returns
     */
    postNotNcSkuIsEnable(id: number, is_enable: number) {
        const params = { id, is_enable };
        return this.baseData.post({ url: '/nnCheck/post_not_nc_sku_is_enable', params });
    }

    /**
     * 提交sku免检审批
     * @param id
     * @param status
     */
    postNotNcSkuApproval(id: number, status) {
        const params = { id, status };
        return this.baseData.post({ url: '/nnCheck/post_not_nc_sku_approval', params });
    }

    /**
     * 提交免检sku
     * @param sku
     * @param sku_chinese
     * @param of_factory
     */
    postNotNcSku(sku: string, sku_chinese: string, of_factory: string) {
        const params = { sku, sku_chinese, of_factory };
        return this.baseData.post({ url: '/nnCheck/post_not_nc_sku', params });
    }

    /**
     * 取消进度确认
     * @param type
     * @param contract_id
     * @param cprogress_option
     * @param sprogress_option
     * @param sku
     */
    cancelProgressTrack(
        type: 'contract' | 'sku',
        contract_id: number,
        cprogress_option: string,
        sprogress_option: string,
        sku: string,
    ) {
        let params = { type, contract_id, cprogress_option, sprogress_option, sku };

        params = JSON.parse(JSON.stringify(params));

        return this.baseData.post({ url: '/progress/cancel_progress_track', params });
    }

    /**
     * 更新进度备注
     * @param contract_id
     * @param shipping_mark_remarks
     * @param sku_list
     * @returns
     */
    updateProgressRemarks(contract_id: number, shipping_mark_remarks: string, sku_list: any[]) {
        let params = { contract_id, shipping_mark_remarks, sku_list };

        params = JSON.parse(JSON.stringify(params));

        return this.baseData.post({ url: '/progress/update_progress_remarks', params });
    }

    /**
     * 上传数据审核回复视频
     * @param apply_inspection_no
     * @param contract_no
     * @param sku
     * @param file
     * @returns
     */
    uploadReplyVideo(apply_inspection_no: string, contract_no: string, sku: string, file: any, comment_id: number) {
        const formData = new FormData();
        formData.append('apply_inspection_no', apply_inspection_no);
        formData.append('contract_no', contract_no);
        formData.append('sku', sku);

        if (comment_id) {
            formData.append('comment_id', comment_id.toString());
        }

        if (file) {
            formData.append('file', file, file.name);
        }

        return this.http.post(this.apiUrl + '/task/upload_reply_video', formData, {
            headers: {
                'Cache-Control': 'no-store',
                Pragma: 'no-cache',
                Expires: '0',
                Accept: 'multipart/form-data',
                Authorization: this.baseData.userInfo.api_token
                    ? `Bearer ${this.baseData.userInfo.api_token}`
                    : undefined,
            },
        });
    }

    /**
     * 删除数据审核回复视频
     * @param apply_inspection_no
     * @param contract_no
     * @param sku
     * @param filename
     * @param comment_id
     */
    delTaskReplyVideo(
        apply_inspection_no: string,
        contract_no: string,
        sku: string,
        filename: string,
        comment_id: number,
    ) {
        const params = { apply_inspection_no, contract_no, sku, filename, comment_id };

        return this.baseData.post({ url: '/task/del_task_reply_video', params });
    }
}
