import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface RadioCardOption {
    value: string;
    key: number;
}

@Component({
    selector: 'app-radio-card',
    templateUrl: './radio-card.component.html',
    styleUrls: ['./radio-card.component.scss'],
})
export class RadioCardComponent implements OnInit {
    @Input() option: RadioCardOption[];
    @Input() selected: RadioCardOption;
    @Output() getSelected: EventEmitter<RadioCardOption> = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    change(item: RadioCardOption) {
        this.getSelected.emit(item);
    }
}
