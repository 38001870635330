import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataStatisticsService } from 'src/app/services/data-statistics.service';

@Component({
    selector: 'app-select-one-user',
    templateUrl: './select-one-user.component.html',
    styleUrls: ['./select-one-user.component.scss'],
})
export class SelectOneUserComponent implements OnInit {
    @Input() placeholder: string;
    @Output() selectedId = new EventEmitter<number>();
    @Output() selectedName = new EventEmitter<string>();

    private _defaultName: string;
    @Input()
    set defaultName(value: string) {
        this._defaultName = value;
        if (this._defaultName) {
            this.searchDefault();
        }
    }
    get defaultName() {
        return this._defaultName;
    }

    id: number;
    data: { id: number; name: string }[] = [];

    isLoading = false;

    constructor(private dataStatisticsService: DataStatisticsService) {}

    ngOnInit() {}

    onSearch(val: string) {
        const userInfo = JSON.parse(sessionStorage.getItem('USERINFO'));
        this.isLoading = true;
        this.dataStatisticsService.getUserByName(userInfo.name).subscribe(result => {
            this.isLoading = false;
            this.data = result.data;
        });
    }

    searchDefault() {
        this.isLoading = true;
        this.dataStatisticsService.getUserByName(this.defaultName).subscribe(result => {
            this.isLoading = false;
            this.data = result.data;
            const id = this.data.filter(item => item.name === this.defaultName)[0].id;
            this.id = id;
            this.selectedId.emit(id);
        });
    }

    change(id: number) {
        if (id === null || id === undefined) {
            this.selectedId.emit(null);
            this.selectedName.emit(null);
            return;
        }

        const name = this.data.filter(item => item.id === id)[0].name;
        this.selectedId.emit(id);
        this.selectedName.emit(name);
    }
}
