import { PageEffectService } from '../../services/page-effect.service';
import { BaseDataService } from '../../services/base-data.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { sku } from 'src/app/pages/task-add/task-add.page';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CreateSupervisionService } from 'src/app/services/create-supervision.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { AddContractPopupComponent } from 'src/app/component/add-contract-popup/add-contract-popup.component';
import { ModalOptions } from '@ionic/core';
import { MyUtils } from 'src/app/utils/my-utils';

@Component({
    selector: 'app-create-supervision-popup',
    templateUrl: 'create-supervision-popup.component.html',
    styleUrls: ['create-supervision-popup.component.scss'],
})
export class CreateSupervisionPopupComponent implements OnInit {
    // 是否有编辑按钮
    @Input() isAllowEdit = false;

    @Input() isEdit = false;

    @Input() set contract(input: any) {
        if (!!input) {
            this._contract = input;
            (this._contract.sku_list ? this._contract.sku_list : this._contract.sku_num).forEach((element, i) => {
                element.index = i;

                element['must_quantity_num'] = element.must_quantity;
                element['quantity_num'] = element.quantity;
            });
        }
    }

    _contract: any;

    @Input() set editDraft(p: any) {
        if (!!p) {
            this.data = p;
        }
    }

    @Input() set remarkLists(p: any) {
        if (!!p) {
            this.remarksArr = p;
        }
    }

    @Input()
    showType: 'input' | 'list';

    @ViewChild('fileInput', { static: false }) fileInput: any;

    data: any = {
        arranged_cabinet_data: [],
        loading_supervision_data: {
            bl_no: '',
            estimate_loading_date: '',
            loading_supervision_batch_no: '',
            installation_sequence: '',
            loading_supervision_type: '',
            supervision_requirements: [],
            draft_no: '',
        },
    };
    remarksArr: { text?: string }[] = [{ text: '' }];
    constructor(
        private createSupervisionService: CreateSupervisionService,
        public baseData: BaseDataService,
        private es: PageEffectService,
        private msg: NzMessageService,
        public myUtils: MyUtils,
        private dialog: NzModalService,
    ) {}

    ngOnInit() {}
    isShowBatchNoWaring = false;
    isShowLadingWaring = false;
    isShowLoadingArea = false;
    isShowDateWaring = false;
    isShowSupervisionType = false;
    enter(type: 'cancel' | 'enter', isDraft: boolean) {
        if (type == 'enter') {
            if (isDraft) {
                this.isShowLadingWaring = false;
                this.isShowLoadingArea = false;
                this.isShowDateWaring = false;
                this.isShowSupervisionType = false;
                if (!this.data.loading_supervision_data.loading_supervision_batch_no) {
                    this.isShowBatchNoWaring = true;
                }

                if (this.isShowBatchNoWaring) {
                    return;
                }

                this.data.loading_supervision_data.supervision_requirements = this.remarksArr.map(item => {
                    return item.text || '';
                });

                let params = {
                    prepare_loading_supervision_data: this.data,
                };

                this.createSupervisionService.submitDraft(params).subscribe(res => {
                    // this.es.modalCtrl.dismiss({
                    //   refresh: this.data,
                    // });
                    if (res.status === 1) {
                        this.msg.success('存入草稿箱成功');
                        // this.data = {
                        //   arranged_cabinet_data: [],
                        //   loading_supervision_data: {
                        //     bl_no: '',
                        //     estimate_loading_date: '',
                        //     loading_supervision_batch_no: '',
                        //     installation_sequence: '',
                        //     loading_supervision_type: '',
                        //     supervision_requirements: [],
                        //     draft_no: ''
                        //   }
                        // };
                        // this.remarksArr = [{}]
                    }
                });
                return;
            }

            if (!this.data.loading_supervision_data.loading_supervision_batch_no) {
                this.isShowBatchNoWaring = true;
            }

            if (!this.data.loading_supervision_data.bl_no) {
                this.isShowLadingWaring = true;
            }

            if (!this.data.loading_supervision_data.installation_sequence) {
                this.isShowLoadingArea = true;
            }

            if (!this.data.loading_supervision_data.estimate_loading_date) {
                this.isShowDateWaring = true;
            }

            if (!this.data.loading_supervision_data.loading_supervision_type) {
                this.isShowSupervisionType = true;
            }

            if (
                this.isShowBatchNoWaring ||
                this.isShowLadingWaring ||
                this.isShowLoadingArea ||
                this.isShowDateWaring ||
                this.isShowSupervisionType
            ) {
                return;
            }

            this.data.loading_supervision_data.supervision_requirements = this.remarksArr.map(item => {
                return item.text || '';
            });

            let params = {
                prepare_loading_supervision_data: this.data,
            };

            this.createSupervisionService.submitCreateSupervision(params).subscribe(res => {
                if (res.status === 0) {
                    this.msg.error(res.message);
                    return;
                }
                this.es.modalCtrl.dismiss({
                    refresh: this.data,
                });
            });
        } else {
            if (this.isEdit) {
                this.isEditfn('yes');
                return;
            }
            this.isEditfn('no');
        }
    }

    isEditfn(str: string) {
        this.es.modalCtrl.dismiss({
            refresh: { cancle: true, isEditCancle: str },
        });
    }

    //上传excel
    onFileChange(event) {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        this.createSupervisionService.excelImport(formData).subscribe(res => {
            if (res.status === 0) {
                this.msg.error(res.message);
                return;
            }
            this.data.arranged_cabinet_data = res.data;

            if (res.not_compliant && res.not_compliant.length > 0) {
                let errorInfoArr = res.not_compliant;
                this.dialog.create({
                    nzTitle: '错误提示',
                    nzContent: `<div>${errorInfoArr.map((item, index) => {
                        return `<p>${index + 1}.${item}</p>`;
                    })}</div>`,
                    nzClosable: false,
                    nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
                });
            }

            this.fileInput.nativeElement.value = '';
        });
    }

    //添加合同
    addContract() {
        let option: ModalOptions = {
            component: AddContractPopupComponent,
            backdropDismiss: false,
            animated: false,
            componentProps: { isAllowEdit: false, showType: 'input', existsDataList: this.data.arranged_cabinet_data },
        };
        this.es.showModal(option, (data: any) => {
            this.addContractResult(data);
        });
    }

    addContractResult(res: any) {
        if (res === true) {
            return;
        }
        this.data.arranged_cabinet_data = this.data.arranged_cabinet_data.concat(res);
        this.addContract();
    }

    deleteContract(index: number) {
        this.data.arranged_cabinet_data.splice(index, 1);
        if (!this.data.arranged_cabinet_data.length) {
            this.data.arranged_cabinet_data = [];
        }
    }

    onChange(date) {
        this.data.loading_supervision_data.estimate_loading_date = date && this.myUtils.dateFormat('YYYY-mm-dd', date);
        this.isShowDateWaring = this.data.loading_supervision_data.estimate_loading_date ? false : true;
    }

    selectRadioChange(e) {
        this.isShowSupervisionType = this.data.loading_supervision_data.loading_supervision_type ? false : true;
    }

    deleteRow(i) {
        this.remarksArr.splice(i, 1);
    }

    addRow() {
        this.remarksArr.push({ text: '' });
    }
}
