import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-energy-disk',
    templateUrl: './energy-disk.component.html',
    styleUrls: ['./energy-disk.component.scss'],
})
export class EnergyDiskComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() centerText: string;
    @Input() centerTextColor: string;
    @Input() height: number = 160;
    @Input() width: number = 160;

    @Input() bigCircleMarkNumber: number = 40; // 一圈刻度个数
    @Input() bigCircleMarkWidth: number = 4; // 刻度粗细
    @Input() bigCircleMarkLength: number = 12; // 刻度长度

    @Input() smallCircleMarkNumber: number = 30; // 一圈刻度个数
    @Input() smallCircleMarkWidth: number = 4; // 刻度粗细
    @Input() smallCircleMarkLength: number = 4; // 刻度长度
    @Input() smallCircleMarkColor: string = '#ececec'; // 刻度颜色
    @Input() smallCircleDistanceOuterRing: number = 25; // 小圈距离外圈距离

    @Input() grdTop: string = '#66C1F5'; // 渐变色顶部
    @Input() grdBottom: string = '#51D0DD'; // 渐变色底部

    bigCircleRadius: number;

    @ViewChild('canvas', { static: true })
    private canvas: ElementRef<HTMLCanvasElement>;

    private interval: any;

    private rotateSpeed = 12;

    constructor() {}

    ngOnInit() {
        this.init();
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }

    init() {
        this.bigCircleRadius = Math.min(this.height, this.width) / 2;
    }

    ngAfterViewInit() {
        const ctx = this.canvas.nativeElement.getContext('2d');

        this.canvas.nativeElement.onmousemove = () => {
            this.rotateSpeed = 60;
        };

        this.canvas.nativeElement.onmouseout = () => {
            this.rotateSpeed = 12;
        };

        ctx.save();
        this.setCenterPoint(ctx);
        ctx.save();

        ctx.lineWidth = this.smallCircleMarkWidth;
        this.drawSmallCircle(ctx);
        ctx.restore();
        ctx.save();

        ctx.lineWidth = this.bigCircleMarkWidth;
        this.drawBigCircle(ctx);
        ctx.restore();
        ctx.save();

        ctx.globalCompositeOperation = 'source-atop';
        this.toColor(ctx);
    }

    setCenterPoint(ctx: CanvasRenderingContext2D) {
        ctx.translate(this.width / 2, this.height / 2);
    }

    drawBigCircle(ctx: CanvasRenderingContext2D) {
        for (let i = 0; i < this.bigCircleMarkNumber; i++) {
            ctx.beginPath();
            ctx.rotate((2 * Math.PI) / this.bigCircleMarkNumber);
            ctx.moveTo(this.bigCircleRadius - this.bigCircleMarkLength, 0);
            ctx.lineTo(this.bigCircleRadius, 0);
            ctx.stroke();
        }
    }

    drawSmallCircle(ctx: CanvasRenderingContext2D) {
        for (let i = 0; i < this.smallCircleMarkNumber; i++) {
            ctx.beginPath();
            ctx.rotate((2 * Math.PI) / this.smallCircleMarkNumber);
            ctx.strokeStyle = this.smallCircleMarkColor;
            ctx.moveTo(this.bigCircleRadius - this.smallCircleDistanceOuterRing - this.smallCircleMarkLength, 0);
            ctx.lineTo(this.bigCircleRadius - this.smallCircleDistanceOuterRing, 0);
            ctx.stroke();
        }
    }

    toColor(ctx: CanvasRenderingContext2D) {
        const grd = ctx.createLinearGradient(0, 0, 0, this.bigCircleRadius * 2);
        grd.addColorStop(0, this.grdTop);
        grd.addColorStop(1, this.grdBottom);
        ctx.lineWidth = 20;
        ctx.strokeStyle = grd;

        let num = 0;
        this.interval = setInterval(() => {
            ctx.rotate((this.rotateSpeed * Math.PI) / 180);
            ctx.arc(0, 0, this.bigCircleRadius, 0, 2 * Math.PI);
            ctx.stroke();

            num++;
            if (num >= 500) {
                ctx.restore();
                ctx.restore();
                ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
                this.ngOnDestroy();
                this.ngAfterViewInit();
            }
        }, 16);
    }
}
