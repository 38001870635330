import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-product-dep-supplementary-notes',
    templateUrl: './product-dep-supplementary-notes.component.html',
    styleUrls: ['./product-dep-supplementary-notes.component.scss'],
})
export class ProductDepSupplementaryNotesComponent implements OnInit {
    @Input() notes: string[] = [];
    @Input() isAllowViewWu = true;
    @Output() curClick = new EventEmitter();
    constructor() {}

    agInit(params: ICellRendererParams): void {
        this.notes = params.value;
    }

    ngOnInit() {}
}
