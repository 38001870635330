import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-into-icon',
    templateUrl: './into-icon.component.html',
    styleUrls: ['./into-icon.component.scss'],
})
export class IntoIconComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
