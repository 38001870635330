import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import md5 from 'js-md5';
import { NzMessageService, UploadXHRArgs } from 'ng-zorro-antd';
import { BaseDataService } from 'src/app/services/base-data.service';
import { environment } from 'src/environments/environment';
import { FiveUploadChunkRequestService } from './five-upload-chunk-request.service';

interface OnSuccessData {
    factory_inspect_no?: string;
    path?: string;
    rework_id?: string;
    type?: string;
    uid?: string;
}

@Injectable({
    providedIn: 'root',
})
export class FiveUploadChunkService {
    private fileMD5 = ''; // 总文件列表
    private file: any;
    private chunkSize: number;
    private chunkCount: number;
    private uploadXHRArgs: UploadXHRArgs;
    private reworkId: number;
    private chunkIndex = 0;

    private environment = environment;

    private onSuccessDataList: OnSuccessData[] = [];

    public percent: { percent: number } = { percent: 0 };

    private isStopUpload = false;

    constructor(
        private fileReader: FileReader,
        private http: HttpClient,
        private baseDataService: BaseDataService,
        private fiveUploadChunkRequestService: FiveUploadChunkRequestService,
        private msg: NzMessageService,
    ) {}

    uploadByPieces(
        file,
        pieceSize = 2,
        success: Function,
        error: Function,
        uploadXHRArgs: UploadXHRArgs,
        reworkId: number,
    ) {
        this.uploadXHRArgs = uploadXHRArgs;
        this.file = file;
        this.reworkId = reworkId;
        this.chunkSize = pieceSize * 1024 * 1024;
        this.chunkCount = Math.ceil(file.size / this.chunkSize); // 总片数
        //('this.chunkSize---', this.chunkSize);
        //('this.chunkCount---', this.chunkCount);

        this.percent = { percent: 0 };
        this.chunkIndex = 0;

        this.readFileMD5(success, error); // 开始执行代码
    }

    // 获取md5
    private readFileMD5(success, error) {
        const tempFun = async e => {
            let fileBolb = (e.target as any).result;
            this.fileMD5 = md5(fileBolb);

            //('this.fileMD5---', this.fileMD5);

            const {
                data: { shouldUpload },
            } = await this.verifyUpload(this.fileMD5);

            this.fileReader.removeEventListener('load', tempFun);

            if (!shouldUpload) {
                success && success();
                alert('文件已上传');
                return;
            } else {
                //("文件未被上传，将分片上传")
                this.readChunkMD5(success, error);
            }
        };

        // 读取视频文件的md5
        //("获取文件的MD5值");
        this.fileReader.readAsBinaryString(this.file);
        this.fileReader.addEventListener('load', tempFun);
    }

    private getChunkInfo(file, currentChunk, chunkSize) {
        let start = currentChunk * chunkSize;
        let end = Math.min(file.size, start + chunkSize);
        let chunk = file.slice(start, end);
        return { start, end, chunk };
    }

    // 针对每个文件进行chunk处理
    private readChunkMD5(success, error) {
        // 针对单个文件进行chunk上传
        const { chunk } = this.getChunkInfo(this.file, this.chunkIndex, this.chunkSize);
        //("总片数" + this.chunkCount)
        //("分片后的数据---测试：" + this.chunkIndex)
        //(chunk)
        this.uploadChunk({ chunk, currentChunk: this.chunkIndex, chunkCount: this.chunkCount }, success, error);
    }

    private async uploadChunk(chunkInfo, success, error) {
        if (this.isStopUpload) {
            setTimeout(() => {
                this.uploadChunk(chunkInfo, success, error);
            }, 1000);
            return;
        }

        // progressFun()
        let formData = new FormData();
        formData.append('chunk', chunkInfo.chunk);
        formData.append('rework_id', this.reworkId + '');
        formData.append('type', 'review_describe_video');
        formData.append('upload_type', 'upload');
        formData.append('chunk_total', chunkInfo.chunkCount);
        formData.append('cut_num', chunkInfo.currentChunk);
        formData.append('index', chunkInfo.currentChunk);
        formData.append('filehash', this.fileMD5);
        formData.append('hash', `${this.fileMD5}__${chunkInfo.currentChunk}`);

        const result = await this.upload(formData);
        //('result---', result);
        if (result.data && result.data.canMerge) {
            const data = {
                rework_id: this.reworkId + '',
                type: 'review_describe_video',
                filehash: this.fileMD5,
                upload_type: 'merge',
                chunk_total: chunkInfo.chunkCount,
            };

            return this.http
                .post(`${this.environment.apiUrl}/task/add_inspection_rework_video`, data, {
                    headers: {
                        Authorization: this.baseDataService.userInfo.api_token
                            ? `Bearer ${this.baseDataService.userInfo.api_token}`
                            : undefined,
                    },
                })
                .subscribe((result: any) => {
                    //('result---', result);
                    if (result.status == 1) {
                        const onSuccessData: OnSuccessData = {};
                        onSuccessData.factory_inspect_no = result.data[0].factory_inspect_no;
                        onSuccessData.path = result.data[0].path;
                        onSuccessData.rework_id = result.data[0].rework_id;
                        onSuccessData.type = result.data[0].type;
                        onSuccessData.uid = this.file.uid;
                        this.onSuccessDataList.push(onSuccessData);
                        //('this.onSuccessDataList---', this.onSuccessDataList);

                        this.percent.percent = 100;
                        this.uploadXHRArgs.onProgress!(this.percent, this.uploadXHRArgs.file!);
                        this.uploadXHRArgs.onSuccess!({}, this.uploadXHRArgs.file!, null);
                        this.msg.success(result.message);
                        success(onSuccessData);
                    } else {
                        this.uploadXHRArgs.onError!(null, this.uploadXHRArgs.file!);
                        this.msg.error(result.message);
                        error();
                    }
                });
        } else {
            this.chunkIndex++;
            this.percent.percent = (this.chunkIndex / chunkInfo.chunkCount) * 100;
            this.uploadXHRArgs.onProgress!(this.percent, this.uploadXHRArgs.file!);
            if (this.chunkIndex < chunkInfo.chunkCount) {
                const { chunk } = this.getChunkInfo(this.file, this.chunkIndex, this.chunkSize);
                //("总片数" + this.chunkCount)
                //("分片后的数据---测试：" + this.chunkIndex)
                //(chunk)
                this.uploadChunk({ chunk, currentChunk: this.chunkIndex, chunkCount: this.chunkCount }, success, error);
            } else {
                alert(result.message);
                this.uploadXHRArgs.onError!(null, this.uploadXHRArgs.file!);
            }
        }
    }

    /**
     * 验证视频上传接口
     */
    private async verifyUpload(fileHash: string) {
        return await this.fiveUploadChunkRequestService.request({
            url: `${this.environment.apiUrl}/task/add_inspection_rework_video`,
            headers: {
                'content-type': 'application/json',
                Authorization: this.baseDataService.userInfo.api_token
                    ? `Bearer ${this.baseDataService.userInfo.api_token}`
                    : undefined,
            },
            data: JSON.stringify({
                filehash: fileHash,
                upload_type: 'fileVerify',
                type: 'review_describe_video',
            }),
        });
    }

    private async upload(formData: any) {
        return await this.fiveUploadChunkRequestService.request({
            url: `${this.environment.apiUrl}/task/add_inspection_rework_video`,
            headers: {
                Authorization: this.baseDataService.userInfo.api_token
                    ? `Bearer ${this.baseDataService.userInfo.api_token}`
                    : undefined,
            },
            data: formData,
        });
    }

    public getSuccessData(uid: string) {
        const item = this.onSuccessDataList.filter(item => item.uid == uid)[0];
        this.onSuccessDataList = this.onSuccessDataList.filter(item => item.uid != uid);
        //('this.onSuccessDataList---', this.onSuccessDataList);
        return item;
    }

    public stopUpload() {
        this.isStopUpload = true;
    }

    public continueUpload() {
        this.isStopUpload = false;
    }
}
