import { PageEffectService } from '../../services/page-effect.service';
import { BaseDataService } from '../../services/base-data.service';
import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NzMessageService } from 'ng-zorro-antd';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { UnloadingSupervisionService } from 'src/app/services/unloading-supervision.service';

interface TreeNodeInterface {
    key?: number;
    expand?: boolean;
    accessory_data?: IaccessoryData[];
    parent?: TreeNodeInterface;
    contract_no?: string;
    factory_code?: string;
    manufacturer?: string;
    manufacturer_address?: string;
    purchaser_user_name?: string;
    schedule_user_name?: string;
    sku?: string;
    sku_chinese_name?: string;
    truly_inspection_num?: string;
    arraying_container_num?: number;
}

interface IaccessoryData {
    key?: number;
    accessory_name?: string;
    accessory_no?: string;
    accessory_num?: number;
    picture?: string;
    sku?: string;
    estimate_loading_num?: number;
}

@Component({
    selector: 'app-create-supervision-popup',
    templateUrl: './add-contract-popup.component.html',
    styleUrls: ['./add-contract-popup.component.scss'],
})
export class AddContractPopupComponent implements OnInit {
    // 是否有编辑按钮
    @Input() isAllowEdit = false;

    _existsDataList: any;
    @Input() set existsDataList(input: any) {
        if (!!input) {
            this._existsDataList = input;
        }
    }

    @Input() set applyInspectId(input: number) {
        if (!!input) this._applyInspectId = input;
    }

    @Input() showBtnGroup: any;

    _applyInspectId: number;

    config: SwiperConfigInterface = {};
    @Input()
    showType: 'input' | 'list';

    @Input() showCancel?: boolean = true;

    public data: any = {
        data: {},
    };

    queryContractNo: string = '';
    contractNoLists: TreeNodeInterface[] = [];
    constructor(
        private unloadingSuperVisionService: UnloadingSupervisionService,
        public baseData: BaseDataService,
        private effectCtrl: PageEffectService,
        private msg: NzMessageService,
    ) {}

    ngOnInit() {}
    listOfParentData: TreeNodeInterface[] = [];

    queryContract() {
        this.unloadingSuperVisionService.queryContract(this.queryContractNo).subscribe(res => {
            if (!res.length) return this.effectCtrl.showToast({ message: '未查询到数据', color: 'danger' });
            // res= res.reduce((pre, cur) => {
            //   return pre.concat(cur)
            // }, [])

            this._existsDataList.forEach(v => {
                res.forEach(p => {
                    if (v.contract_no === p.contract_no && v.sku === p.sku) {
                        p.truly_inspection_num -= v.arraying_container_num;
                        if (v.accessory_data && v.accessory_data.length) {
                            p.accessory_data.forEach((i, index) => {
                                v.accessory_data.forEach(j => {
                                    if (i.accessory_no === j.accessory_no) {
                                        i.accessory_num -= j.estimate_loading_num;
                                    }
                                });
                            });
                        }
                    }
                });
            });
            this.contractNoLists = res;
            this.listOfParentData = this.fnOne(this.contractNoLists);
        });
    }

    fnOne(dataLists: TreeNodeInterface[]) {
        return dataLists.map((e, i) => {
            return {
                key: i,
                contract_no: e.contract_no,
                factory_code: e.factory_code,
                manufacturer: e.manufacturer,
                manufacturer_address: e.manufacturer_address,
                purchaser_user_name: e.purchaser_user_name,
                schedule_user_name: e.schedule_user_name,
                sku: e.sku,
                sku_chinese_name: e.sku_chinese_name,
                truly_inspection_num: e.truly_inspection_num,
                expand: false,
                accessory_data: this.fnTwo(e.accessory_data),
            };
        });
    }

    fnTwo(dataLists: IaccessoryData[]) {
        return dataLists.map((e, i) => {
            return {
                key: i,
                accessory_name: e.accessory_name,
                accessory_no: e.accessory_no,
                accessory_num: e.accessory_num,
                picture: e.picture,
                sku: e.sku,
            };
        });
    }

    // convertTreeToList(root: any): any[] {
    //   const stack: any[] = [];
    //   const array: any[] = [];
    //   const hashMap = {};  //已存放的父节点是否映射
    //   stack.push({ ...root, level: 0, expand: false });

    //   while (stack.length !== 0) {
    //     const node = stack.pop()!;
    //     this.visitNode(node, hashMap, array);
    //     if (node.split_contract) {
    //       for (let i = node.split_contract.length - 1; i >= 0; i--) {
    //         stack.push({
    //           ...node.split_contract[i],
    //           level: node.level! + 1,
    //           expand: false,
    //           parent: node,
    //           master_contract_no: node.contract_no ? node.contract_no : '',
    //           master_apply_inspection_no: node.apply_inspection_no
    //         });
    //       }
    //     }
    //   }

    //   return array;
    // }

    // visitNode(
    //   node: any,
    //   hashMap: { [id: string]: boolean },
    //   array: any[]
    // ): void {
    //   if (!hashMap[node.id]) {
    //     hashMap[node.id] = true;
    //     array.push(node);
    //   }
    // }

    // collapse(
    //   array: any[],
    //   item: any,
    //   $event: boolean,
    // ): void {
    //   // //('展开array', array);
    //   // //('展开item', item);
    //   // //($event);

    //   if ($event === false) {

    //     // if (data) {
    //     //   data.isShowSliptBox = false
    //     //   data.split_contract_no = ''
    //     //   data.split_sku_sum = null
    //     // }
    //     if (item.split_contract) {
    //       item.split_contract.forEach((d) => {
    //         const target = array.find((a) => a.id === d.id)!;
    //         target.expand = false;
    //         this.collapse(array, target, false);
    //       });
    //     } else {
    //       return;
    //     }
    //   } else {
    //     return
    //   }
    // }

    enter(type: 'cancel' | 'enter') {
        if (type == 'enter') {
            if (this.listOfParentData.some(item => item.arraying_container_num > Number(item.truly_inspection_num))) {
                return this.effectCtrl.showToast({
                    message: '装柜箱数不能大于可装柜数量',
                    color: 'danger',
                });
            }

            let innerData = this.listOfParentData.filter(item =>
                item.accessory_data.some(i => i.estimate_loading_num > 0),
            );
            if (innerData.some(item => item.accessory_data.some(i => i.estimate_loading_num > i.accessory_num))) {
                return this.effectCtrl.showToast({
                    message: '配件装柜箱数不能大于可装柜数量',
                    color: 'danger',
                });
            }

            let submitDataLists = this.listOfParentData.filter(item => {
                let temp = [];
                if (item.arraying_container_num >= 0) {
                    temp = item.accessory_data.filter(i => i.estimate_loading_num > 0);
                }
                if (temp.length === 0) {
                    return item.arraying_container_num > 0;
                } else {
                    return item.arraying_container_num !== null && item.arraying_container_num >= 0;
                }
            });

            //清空aceessory_data里没有estimate_loading_num的数据
            submitDataLists.forEach(item => {
                item.accessory_data = item.accessory_data.filter(i => i.estimate_loading_num > 0);
            });

            if (!submitDataLists.length) {
                this.effectCtrl.showToast({
                    message: '请输入装柜箱数',
                    color: 'danger',
                });
                return;
            }
            this.effectCtrl.modalCtrl.dismiss({
                refresh: submitDataLists,
            });

            this.effectCtrl.showToast({
                message: '添加成功',
                color: 'success',
            });
        } else {
            this.effectCtrl.modalCtrl.dismiss({
                refresh: true,
            });
        }
    }

    numberChange(e, item) {
        if (Number.parseInt(e.target.value) < 0) {
            e.target.value = 0;
        }

        //必须输入整数
        if (!Number.isInteger(Number(e.target.value))) {
            e.target.value = Math.trunc(Number(e.target.value));
        }
    }
}
