import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MyUtils } from '../utils/my-utils';
import { BaseDataService, userInfo } from './base-data.service';

@Injectable({
    providedIn: 'root',
})
export class CreateSupervisionService {
    apiUrl: string = environment.apiUrl;

    constructor(private baseData: BaseDataService, private myUtils: MyUtils, private http: HttpClient) {}

    /**
     * 获取创建监装列表
     */
    getCreateSupervisionList(params: {}) {
        return this.baseData.get({ url: '/loading_supervision/get_prepare_loading_supervision', params });
    }

    /**
     * 查询合同号
     */
    queryContract(contract_or_sku: string) {
        const params = { contract_or_sku };
        return this.baseData.get({ url: '/loading_supervision/get_can_loading_supervision_sku', params });
    }

    /**
     * 提交创建
     */
    submitCreateSupervision(params: any) {
        return this.baseData.post({ url: '/loading_supervision/post_prepare_loading_supervision', params });
    }

    /**
     * 撤销
     */
    cancelSupervisionData(params) {
        return this.baseData.post({ url: '/loading_supervision/reset_prepare_loading_supervision', params });
    }

    /**
     * 提交监装
     */
    submitSupervisionData(params: any) {
        return this.baseData.post({ url: '/loading_supervision/update_prepare_status', params });
    }

    /**
     * 获取草稿箱列表
     */
    getDraftsLists() {
        return this.baseData.get({ url: '/loading_supervision/get_draft_list' });
    }

    /**
     * 从草稿箱获取草稿详情
     */
    getDraftsDetails(params: { loading_supervision_batch_no: string }) {
        return this.baseData.get({ url: '/loading_supervision/get_draft_info', params });
    }

    /**
     * 从列表获取草稿详情
     */
    getListsDraftsDetails(params: { loading_supervision_batch_no: string }) {
        return this.baseData.get({ url: '/loading_supervision/get_prepare_loading_info', params });
    }

    /**
     * 删除草稿
     */
    DeleteDraft(params: any) {
        return this.baseData.post({ url: '/loading_supervision/del_draft', params });
    }

    /**
     * 存入草稿箱
     */
    submitDraft(params: any) {
        return this.baseData.post({ url: '/loading_supervision/post_draft_data', params });
    }

    /**
     * excel导入
     */
    excelImport(params: any) {
        return this.baseData.post(
            { url: '/loading_supervision/uploadExcelData', params },
            undefined,
            'multipart/form-data',
        );
    }
}
