import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-collaborative-task',
    templateUrl: './collaborative-task.component.html',
    styleUrls: ['./collaborative-task.component.scss'],
})
export class CollaborativeTask implements OnInit {
    @Input() itemImgUrl: string;
    @Input() itemNumber: number;
    @Input() itemTitle: string;

    constructor(private router: Router) {}

    ngOnInit() {
        /*初始化加载的生命周期函数*/
    }
}
