import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { Advise } from 'src/app/services/examine.service';
import { CommitService } from 'src/app/services/commit.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { takeWhile } from 'rxjs/operators';
import { OaService } from 'src/app/services/oa.service';
import { NzMessageService } from 'ng-zorro-antd';
import { DataStatisticsService } from 'src/app/services/data-statistics.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-commit-reply',
    templateUrl: './commit-reply.component.html',
    styleUrls: ['./commit-reply.component.scss'],
})
export class CommitReplyComponent implements OnInit {
    usFileUrl = environment.usFileUrl.replace('storage/', '');

    _data: Advise = null;
    currentComment: any = {};
    commentBoxShow: boolean;
    editor: any;
    defaultMessage: any = null;
    pushComplete: boolean = false;

    oaToken: string = '';
    @Input() set data(input: Advise) {
        if (!!input) {
            this._data = input;
        }
    }

    @Input() index: number = null;

    @Output() onReply: EventEmitter<boolean> = new EventEmitter();

    @Output() onComplete: EventEmitter<{ content: string; id: number }> = new EventEmitter();
    constructor(
        public commitCtrl: CommitService,
        private baseData: BaseDataService,
        private oa: OaService,
        private msg: NzMessageService,
        private dataStatisticsService: DataStatisticsService,
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {}

    async getToke(): Promise<any> {
        const { data } = <any>await this.oa.getToken();
        this.oaToken = data;
        this.getOaUserInfo('XD118').then(res => {
            //(res);
        });
    }

    getOaUserInfo(oa: string) {
        return this.oa.getUserInfo(oa, this.oaToken);
    }

    reply(p: Advise) {
        this.pushComplete = false;
        this.commitCtrl.index = this.index;
        this.currentComment = p;
    }

    editorDataComplete(e: string, id: number) {
        let active: boolean = true;

        //此处id为null只有跟节点的时候
        const data = { content: e, id: id, file: this.file };
        this.onComplete.emit(data);

        this.pushComplete = !this.pushComplete;
        this.commitCtrl.currentKey$.pipe(takeWhile(() => active)).subscribe((res: number) => {
            this.insertTree(id, e, [this._data], res);
            active = false;
        });
    }

    insertTree(id: number, content: string, tree: Array<Advise>, targetId: number, isVideo?: boolean) {
        const treeItem = {
            apply_inspection_no: this.commitCtrl.applyInspectNo,
            contents: isVideo ? null : content,
            created_at: <string>(<unknown>new Date().getTime()),
            id: targetId,
            pid: null,
            sku: this.commitCtrl.Sku,
            updated_at: new Date().getTime() as any,
            user: { id: this.baseData.userInfo.id, name: this.baseData.userInfo.name },
            user_id: this.baseData.userInfo.id,
        };

        if (isVideo) {
            treeItem['reply_video'] = [content];
        }

        //('treeItem', treeItem);

        tree.forEach(item => {
            if (item.id === id) {
                if (item.son && item.son.length) {
                    treeItem.pid = item.id;
                    item.son.push(treeItem);
                    return;
                } else {
                    treeItem.pid = item.id;
                    item.son = [treeItem];
                }
                return;
            } else {
                item.son && item.son.length && this.insertTree(id, content, item.son, targetId, isVideo);
            }
        });
    }

    @ViewChild('uploadVideo', { static: false }) uploadVideo: ElementRef;
    uploadReplyVideo() {
        this.uploadVideo.nativeElement.click();
    }
    file: any;
    uploadVideoFileChange(e: any, comment_id: number) {
        this.file = e.target.files[0] || undefined;

        if (this.file.size > 1024 * 1024 * 30) {
            this.file = null;
            this.msg.error('附件大小不可超过30M！', { nzDuration: 6000 });
            return;
        }

        // this.dataStatisticsService.uploadReplyVideo(this.commitCtrl.applyInspectNo, this.commitCtrl._contract, this.commitCtrl.Sku, this.file, comment_id).subscribe((result: any) => {
        //     this.msg[result.status ? 'success' : 'error'](result.message);
        //     if (result.status) {
        //         this.pushComplete = !this.pushComplete;
        //         this.insertTree(comment_id, result.data[0], [this._data], result.pid, true);
        //     }
        // });
    }

    delTaskReplyVideo(comment_id: number, filename: string, replyVideo: string[]) {
        this.dataStatisticsService
            .delTaskReplyVideo(
                this.commitCtrl.applyInspectNo,
                this.commitCtrl._contract,
                this.commitCtrl.Sku,
                filename,
                comment_id,
            )
            .subscribe((result: any) => {
                this.msg[result.status ? 'success' : 'error'](result.message);
                if (result.status) {
                    const tempVideoList = replyVideo.filter(item => item !== filename);
                    replyVideo.splice(0, replyVideo.length, ...tempVideoList);
                }
            });
    }

    reflushInputFile = true;
    removeFile() {
        this.file = null;
        this.reflushInputFile = false;
        setTimeout(() => {
            this.reflushInputFile = true;
        }, 0);
    }
}
