import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-simple-batch-box',
    templateUrl: './simple-batch-box.component.html',
    styleUrls: ['./simple-batch-box.component.scss'],
})
export class SimpleBatchBoxComponent implements OnInit {
    @Input() contractNo: string;
    @Input() sku: string;
    @Input() num: string;
    @Input() isPrepare: number;

    constructor() {}

    ngOnInit() {}
}
