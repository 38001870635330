import { UnloadingSupervisionService } from 'src/app/services/unloading-supervision.service';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LoadingSupervisionService } from 'src/app/services/loading-supervision.service';

@Component({
    selector: 'app-pdf-img',
    templateUrl: './pdf-img.component.html',
    styleUrls: ['./pdf-img.component.scss'],
})
export class PdfImgComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() width: string;
    @Input() height: string;
    @Input() reportType: string = 'loadingSupervision';
    @Input()
    set url(url: string) {
        // this._url = url + '?tamp=' + new Date().getTime();
        this._url = url;
        //https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg
        //https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg

        //随机取一个
        // this._url =
        //     Math.random() > 0.5
        //         ? 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        //         : 'https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg';
    }
    get url() {
        return this._url;
    }
    _url = '';

    isCloseSkeleton = false;

    img: HTMLImageElement;

    constructor(
        private loadingSupervisionService: LoadingSupervisionService,
        private unloadingSupervisionService: UnloadingSupervisionService,
    ) {}

    ngOnInit() {
        /*  //   this.loadingSupervisionService.pdfImgLoadMap[this.url] = false;
        this.reportType === 'loadingSupervision'
            ? (this.loadingSupervisionService.pdfImgLoadMap[this.url] = false)
            : (this.unloadingSupervisionService.pdfImgLoadMap[this.url] = false);

        this.getBase64Image(this.url, (url: string) => {
            const imgEl = document.getElementById(this.url);
            imgEl.setAttribute('src', url);
            this.isCloseSkeleton = true;
            //   this.loadingSupervisionService.pdfImgLoadMap[this.url] = true;
            this.reportType === 'loadingSupervision'
                ? (this.loadingSupervisionService.pdfImgLoadMap[this.url] = true)
                : (this.unloadingSupervisionService.pdfImgLoadMap[this.url] = true);
            this.testPdfImgLoadMap();
        }); */
    }

    ngAfterViewInit(): void {
        // this.unloadingSupervisionService.pdfImgLoadMap[this.url] = false;
        this.reportType === 'loadingSupervision'
            ? (this.loadingSupervisionService.pdfImgLoadMap[this.url] = false)
            : (this.unloadingSupervisionService.pdfImgLoadMap[this.url] = false);
        const imgEl = document.getElementById(this.url) as HTMLImageElement;
        imgEl.src = this.url;
        imgEl.onload = () => {
            this.isCloseSkeleton = true;
            // this.unloadingSupervisionService.pdfImgLoadMap[this.url] = true;
            this.reportType === 'loadingSupervision'
                ? (this.loadingSupervisionService.pdfImgLoadMap[this.url] = true)
                : (this.unloadingSupervisionService.pdfImgLoadMap[this.url] = true);
            this.testPdfImgLoadMap();
        };
    }

    testPdfImgLoadMap() {
        let length =
            this.reportType === 'loadingSupervision'
                ? Object.keys(this.loadingSupervisionService.pdfImgLoadMap).length
                : Object.keys(this.unloadingSupervisionService.pdfImgLoadMap).length;

        let loadLength = 0;

        this.reportType === 'loadingSupervision'
            ? (this.loadingSupervisionService.pdfImgSize = length)
            : (this.unloadingSupervisionService.pdfImgSize = length);

        for (const key in this.reportType === 'loadingSupervision'
            ? this.loadingSupervisionService.pdfImgLoadMap
            : this.unloadingSupervisionService.pdfImgLoadMap) {
            if (
                !(this.reportType === 'loadingSupervision'
                    ? this.loadingSupervisionService.pdfImgLoadMap[key]
                    : this.unloadingSupervisionService.pdfImgLoadMap[key])
            ) {
                break;
            }
            loadLength++;
            this.reportType === 'loadingSupervision'
                ? this.loadingSupervisionService.pdfDown.next({ length, loadLength })
                : this.unloadingSupervisionService.pdfDown.next({ length, loadLength });
            this.reportType === 'loadingSupervision'
                ? (this.loadingSupervisionService.pdfImgLoadSize = loadLength)
                : (this.unloadingSupervisionService.pdfImgLoadSize = loadLength);
        }
        if (length === loadLength) {
            this.reportType === 'loadingSupervision'
                ? (this.loadingSupervisionService.pdfImgLoadMap = {})
                : (this.unloadingSupervisionService.pdfImgLoadMap = {});
            this.reportType === 'loadingSupervision'
                ? (this.loadingSupervisionService.pdfIsCanDown = true)
                : (this.unloadingSupervisionService.pdfIsCanDown = true);
        }
    }

    getBase64Image(src: string, cb: Function) {
        this.img = document.createElement('img');
        let dataURL = '';
        this.img.setAttribute('crossOrigin', 'anonymous');
        this.img.src = src;
        this.img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = this.img.width;
            canvas.height = this.img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(this.img, 0, 0, this.img.width, this.img.height);
            dataURL = canvas.toDataURL();
            cb(dataURL);
        };
    }

    ngOnDestroy() {
        // this.img.onload = null;
        this.loadingSupervisionService.pdfImgLoadMap = {};
        this.unloadingSupervisionService.pdfImgLoadMap = {};
    }
}
