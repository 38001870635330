import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Container } from 'src/app/pages/loading-cabinet/loading-cabinet.component';

@Component({
    selector: 'app-container-box',
    templateUrl: './container-box.component.html',
    styleUrls: ['./container-box.component.scss'],
})
export class ContainerBoxComponent implements OnInit {
    @Input() next: string;
    @Input() go: string;
    @Input() container: Container;
    @Input() departurePortKey: number;
    @Input() portOfArrivalKey: number;
    @Input() portDepartureOptions: object;
    @Input() arrivalPortOptions: object;

    maxShowNum = 5;

    constructor(private router: Router) {}

    ngOnInit() {}

    goNext() {
        this.router.navigate([this.go], {
            queryParams: {
                container: JSON.stringify(this.container),
                portDepartureOptions: JSON.stringify(this.portDepartureOptions),
                arrivalPortOptions: JSON.stringify(this.arrivalPortOptions),
            },
        });
    }
}
