import { Injectable } from '@angular/core';
import { area } from 'src/assets/data/area';

@Injectable({
    providedIn: 'root',
})
export class MyUtils {
    constructor() {}

    //计算两个数相乘
    Mul(arg1: string | number, arg2: string | number) {
        let m = 0,
            s1 = arg1 + '',
            s2 = arg2 + '';
        //获取两个相乘数据的小数点后面的数据的长度，即获取小数的位数，因为最终相乘计算的结果：结果小数的位数=第一个数的小数位数+第二个数的小数位数
        try {
            m += s1.split('.')[1].length;
        } catch (e) {}
        try {
            m += s2.split('.')[1].length;
        } catch (e) {}
        //将两个小数去掉小数点，强制转换整个值（即进行数值放开小数点位数的倍数），然后进行相乘的操作，相乘的结果除去10的m次方
        return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
    }

    dateFormat(fmt, date) {
        let ret;
        const opt = {
            'Y+': date.getFullYear().toString(), // 年
            'm+': (date.getMonth() + 1).toString(), // 月
            'd+': date.getDate().toString(), // 日
            'H+': date.getHours().toString(), // 时
            'M+': date.getMinutes().toString(), // 分
            'S+': date.getSeconds().toString(), // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp('(' + k + ')').exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
            }
        }
        return fmt;
    }

    stringExistVerify(value: string) {
        if (value != null && value != undefined && value.trim().length > 0) {
            return true;
        }
        return false;
    }

    cloneDeep(obj: any) {
        const isObject = Object.prototype.toString.call(obj) === '[object Object]';
        const isArray = Object.prototype.toString.call(obj) === '[object Array]';
        if (isObject) {
            const newObj: any = {};
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    newObj[key] = this.cloneDeep(obj[key]);
                }
            }
            return newObj;
        } else if (isArray) {
            const newArr: any = [];
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    newArr[key] = this.cloneDeep(obj[key]);
                }
            }
            return newArr;
        } else {
            return obj;
        }
    }

    existValidation(args: any[]) {
        for (const arg of args) {
            if (arg == null || arg == undefined || (typeof arg === 'string' && arg.trim().length === 0)) {
                return false;
            }
        }
        return true;
    }

    initArea(options: any[]) {
        for (const key in area[86]) {
            const value = area[86][key];

            const children = [];

            for (const i in area[key]) {
                const value = area[key][i];
                const item = {
                    value: value,
                    label: value,
                    isLeaf: true,
                };
                children.push(item);
            }

            const item = {
                value,
                label: value,
                children,
            };

            options.push(item);
        }
    }

    fileToBase64(file: File) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function(event: any) {
                resolve(event.target.result);
            };
        });
    }
}
